<template>
  <el-dialog
    v-draggable
    :visible.sync="visible"
    :title="title"
    :width="Number(options.canvasWidth) + 'px'"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    top="2vh"
    :before-close="closeDialog"
  >
    <sketchpad :imgId="imgId" :notes="notes" :noteType="noteType" @handleClose="saveAsImg"></sketchpad>
  </el-dialog>
</template>
<script>
import sketchpad from "./sketchpad.vue";

export default {
  name: "c-sign-canvas",
  props: {
    title: {
      default: "学习笔记写字板",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    isAdd: {
      default: true,
      type: Boolean,
    },
    imgId: {
      default: 'note_img_001_',
      type: String,
    },
    notes: {},
    noteType: {
      default: 1,
      type: Number,
    },
  },
  components: {
    sketchpad,
  },
  data() {
    return {
      value: null,
      isEdit: false,
      options: {
        lastWriteSpeed: 1, //书写速度 [Number] 可选
        lastWriteWidth: 2, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
        lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 1300, //canvas宽高 [Number] 可选
        canvasHeight: 300, //高度  [Number] 可选
        isShowBorder: true, //是否显示边框 [可选]
        bgColor: "#e2f7f9", //背景色 [String] 可选 注:这背景色仅仅只是canvas背景,保存的图片仍然是透明的
        borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: "#7ba4a7", //网格颜色  [String] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: "#101010", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
    };
  },
  methods: {
    /**
     * 清除画板
     */
    canvasClear() {
      this.$refs.SignCanvas.canvasClear();
    },
    closeDialog() {
      this.$emit("update:visible", false);
    },

    /**
     * 保存图片
     */
    saveAsImg(img,canvas,noteType) {
      // const img = this.$refs.SignCanvas.saveAsImg();
      this.$emit("change", img,canvas,noteType);
      this.closeDialog();
    },

    /**
     * 下载图片
     */
    downloadSignImg() {
      this.$refs.SignCanvas.downloadSignImg();
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.title {
  padding: 20px;

  text-align: center;
}
.el-dialog__header {
  padding: 20px 20px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  .el-dialog__title {
    position: absolute;
    margin-top: -10px;
  }
}
.sign-canvas-content {
  max-height: 70vh;
  overflow: auto;
}
.sign-canvas {
  display: block;
  margin: 0 auto;
}
.view-image {
  display: block;
  margin: 20px auto;
}
.config {
  // width: 350px;
  // margin: 20px auto;
  .ul-config {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .li-c {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      .item-label {
        font-size: 14px;
      }
      .item-content {
        margin-left: 10px;
      }
    }
  }
}
.el-dialog__footer {
  border-top: 1px solid #eaeefb;
}
.el-input-number ::v-deep.el-input-number__decrease, .el-input-number ::v-deep.el-input-number__increase {
  display: inline-block;
  height: 38px;
  [class*=" el-icon-"], [class^=el-icon-] {
    line-height: 38px !important;
  }
}


</style>
