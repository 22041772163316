<template>
    <div class="caseLayout details">
        <div class="case-conent-detal Loading-backg" v-if="dlog1 !=null ||dlog2 !=null">
          <div class="kong-loshd">
                <img src="../../assets/images/kong.png">
                <p style="font-size: 22px;color: #1A86FF">请等候！数据在努力加载中...</p>
            </div>
        </div>
        <div class="case-conent-detal" v-else>
            <!--第一块-->
            <div class="details-A">
                <div class="area-lf" :style="{ 'width': !showQuestion ? '100%': '' }">
                    <!--左侧内容-->
                    <div class="col_l_main">
                        <!--轮播图内容-->
                        <div class="mod-1">
                            <div class="Hd">
                                <div class="lf">
                                    <img src="../../assets/images/Case-01.png" alt=""> 学历案查看
                                    <el-button @click="backA()" icon="el-icon-back" type="primary" class="Btn-2" size="mini" round>  返回</el-button>
                                </div>
                                <div class="bc">
                                    <div class="dtY">
                                        <el-select class="Btn-1" @change="timeChang($event)"  v-model="query.id">
                                            <el-option
                                                    v-for="item in timeData"
                                                    :key="item.id"
                                                    :label="item.id"
                                                    :value="item.id"
                                            ></el-option>
                                        </el-select>
                                        秒/祯
                                        <el-button class="Btn-2" @click="alwaysA()" style="border: 1px solid #AAD7F9;background-color: #AAD7F9;color: #2c3e50" size="mini" round>
                                            <span v-if="alwaysName=='播放'">{{alwaysName}} <i class="el-icon-caret-right"></i></span>
                                            <span v-else>{{alwaysName}} <i class="el-icon-video-pause"></i></span>
                                        </el-button>
                                    </div>
                                    <div class="dtYPag">
                                        第{{ indexPic + 1 }}张 / 共{{ total }}页
                                    </div>
                                </div>
                                <div class="rg" v-if="isMobile==2">
                                    <el-button :disabled="disabled" @click="opENClass()" class="k" size="mini" round>
                                        打开Class客户端，查看学历案
                                    </el-button>
                                    <el-tooltip effect="dark" content="下载客户端" placement="top" class="rF">
                                        <el-button :disabled="disabled" @click="download()" size="small" type="danger" icon="el-icon-download" class="Btn-3" circle></el-button>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="focus_PiC">
                                <div class="scrollList">
                                    <div class="Pic-left">
                                        <div class="scrollbar infinite-list-wrapper">
                                            <el-scrollbar ref="scroll">
                                                <div>
                                                    <ul
                                                            class="list"
                                                            v-infinite-scroll="queryXlaImg"
                                                            infinite-scroll-disabled="disabledNoMore">
                                                        <div v-for="(item, index) in picList" :key="index" :class="item.uid == indexPic ? 'pic-item active' : 'pic-item'" @click="clickItem(item, index)">
                                                            <img :src="'data:image/png;base64,'+item.base64" style="width:100%;">
                                                        </div>
                                                    </ul>
                                                    <p v-if="loading">加载中...</p>
                                                    <p v-if="noMore">没有更多了</p>
                                                </div>
                                            </el-scrollbar>
                                        </div>
                                    </div>
                                    <div class="Pic-right" v-if="alwaysName=='播放'">
                                        <el-carousel :autoplay="false" arrow="always" indicator-position="none" :initial-index="indexPic" ref="carousel"  @change="changeCarousel">
                                            <el-carousel-item v-for="(item, index) in picList" :key="index" >
                                                <img  @click="ingObj(item)" :src="'data:image/png;base64,'+item.base64">
                                            </el-carousel-item>
                                        </el-carousel>
                                    </div>
                                    <div class="Pic-right" v-else>

                                        <el-carousel :interval="interval" :autoplay="true" arrow="always" indicator-position="none" :initial-index="indexPic"   @change="changeCarousel">
                                            <el-carousel-item v-for="(item, index) in picList" :key="index" >
                                                <img  @click="ingObj(item)" :src="'data:image/png;base64,'+item.base64">
                                            </el-carousel-item>
                                        </el-carousel>
                                    </div>
                                </div>
                                <!--                <div class="pag-box" >-->
                                <!--                  <div class="total-box">-->
                                <!--                    <span class="total_pag">共<em> {{ total }} </em>条</span>-->
                                <!--                  </div>-->
                                <!--                  <el-pagination-->
                                <!--                          :current-page="currentPage"-->
                                <!--                          background-->
                                <!--                          :page-size="pageSize"-->
                                <!--                          layout="prev, pager, next"-->
                                <!--                          :total="total"-->
                                <!--                          @size-change="handleSizeChange"-->
                                <!--                          @current-change="handleCurrentChange"-->
                                <!--                  >-->
                                <!--                    <span class="pag_sise">每页显示</span>-->
                                <!--                  </el-pagination>-->
                                <!--                </div>-->
                            </div>
                        </div>
                        <!--答疑与一课一练内容-->
                        <div class="mod-2">
                            <div class="Hd">
                                <li @click="CaseCurObjA()" :class="{activeA:CaseCurA==0}"><img src="../../assets/images/Case-06.png" alt=""> 学历案交互答疑</li>
                                <li @click="CaseCurObjB()" :class="{activeA:CaseCurA==1}"><img src="../../assets/images/Case-04.png" alt=""> 一课一练</li>
                            </div>
                            <div class="mod-2-content">
                                <div class="mod-2-content-itme" v-show="CaseCurA==0">
                                    <div class="answering" v-if="TopicListData.length>0">
                                        <div class="top">
                                            <el-button :disabled="disabled" type="warning" size="mini" round @click="IwantQuestionObj()"
                                                       class="bTn">
                                                我要提问
                                            </el-button>
                                        </div>
                                        <div class="answeringQuestion">
                                            <div class="answering-lf">
                                                <!--问题(或主题)列表-->
                                                <div class="prolist" v-for="(item,index) in TopicListData" :key="index" @click="changeTopicList(index,item),cur=index" :class="{activeAnswering:cur==index}">
                                                    <div class="pl01">
                                                        <div class="pA">{{item.topic}}</div>
                                                        <div class="pB">
                                                            <el-button v-if="item.isReplay==1" size="mini" round class="ansBtn0">
                                                                专家已回答
                                                            </el-button>
                                                            <!--                              <el-button v-else size="mini" round class="ansBtnA" style="border: 1px solid #299B4C; background-color: #299B4C; color: #fff">-->
                                                            <!--                                未回答-->
                                                            <!--                              </el-button>-->
                                                        </div>
                                                    </div>
                                                    <div class="pl02">
                                                        <div class="pA spaceD" :title="item.nickName">{{item.nickName}}</div>
                                                        <div class="pB">{{item.createTime}}</div>
                                                        <div class="pC">
                                                            有{{item.counts}}个讨论

                                                            <span v-if="NoBuy==1">
                                                                <el-popover
                                                                        placement="bottom"
                                                                        title="标题"
                                                                        width="200"
                                                                        trigger="click"
                                                                        content="您未购买，没有回复权限！">
    <el-button slot="reference" type="primary" plain round  class="ansBtnA" disabled>回复</el-button>
  </el-popover>

                                                            </span>
                                                            <span v-else>
                                                                <el-button  type="primary" plain @click="hf(index,item)" size="mini" round class="ansBtnA">回复</el-button>
                                                            </span>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="answering-rg">
                                                <!--聊天列表-->
                                                <div class="lt-inf">

                                                    <div class="lt-twwt" v-if="TcReplyLData.length > 0 ">
                                                        <div class="Tip">提问</div>

                                                        <div class="TntToplist">

                                                            <div class="portrait">
                                                                <img v-if="TopicReplyListChange.profilePhotoPath=='1'" src="../../assets/images/m1.png" alt="">
                                                                <img v-if="TopicReplyListChange.profilePhotoPath=='2'" src="../../assets/images/m2.png" alt="">
                                                                <img v-if="TopicReplyListChange.profilePhotoPath=='3'" src="../../assets/images/m3.png" alt="">
                                                                <img v-if="TopicReplyListChange.profilePhotoPath=='4'" src="../../assets/images/m4.png" alt="">
                                                                <img v-if="TopicReplyListChange.profilePhotoPath=='5'" src="../../assets/images/m5.png" alt="">
                                                                <p>{{TopicReplyListChange.nickName}}</p>
                                                            </div>
                                                            <div class="intR-content">
                                                                <div class="tR01">
                                                                    <div class="lT-A1">{{TopicReplyListChange.topic}}</div>
                                                                    <div class="lT-A2">{{TopicReplyListChange.createTime}}</div>
                                                                </div>
                                                                <div class="tR02">
                                                                    <div class="lT-content">
                                                                        <!--                                      <viewer :images="photos" @dblclick="showImg()" ">-->
                                                                        <span v-html="TopicReplyListChange.content" ></span>
                                                                        <!--                                      </viewer>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="isHas == 0 ">
                                                        <div class="lt-list"  v-for="(int,idx) in TcReplyLData" :key="idx"  >
                                                            <div class="intlist" :class="idx==index?'TcReply':''" @click="changeTopicListB(idx,int)">

                                                                <div class="portrait">
                                                                    <img v-if="int.photo=='999'" src="../../assets/images/intellect.png" alt="">
                                                                    <img v-if="int.photo=='1'" src="../../assets/images/m1.png" alt="">
                                                                    <img v-if="int.photo=='2'" src="../../assets/images/m2.png" alt="">
                                                                    <img v-if="int.photo=='3'" src="../../assets/images/m3.png" alt="">
                                                                    <img v-if="int.photo=='4'" src="../../assets/images/m4.png" alt="">
                                                                    <img v-if="int.photo=='5'" src="../../assets/images/m5.png" alt="">
                                                                    <img v-else-if="int.photo==''" src="../../assets/images/item-book-bg2.png" alt="">
                                                                    <!--                    <img src="../../assets/images/item-book-bg2.png" alt="">-->
                                                                </div>
                                                                <div class="intR">
                                                                    <div class="tR01">
                                                                        <div class="lf spaceD" :title="int.nickName">
                                                                            {{int.nickName}}
                                                                            <!--                                        <span v-if="int.photo!='999'"><el-button class="ansBtnB" @click="changeLisDL(int)" :disabled="disabled"  type="primary" plain size="mini">回复</el-button>-->
                                                                            <!--</span>-->
                                                                        </div>
                                                                        <div class="rg">{{int.createTime}}</div>
                                                                    </div>
                                                                    <div class="tR02">
                                                                        <!--                                      <viewer :images="photo">-->
                                                                        <span v-html="int.content"></span>
                                                                        <!--                                    <img v-for="(item,index) in photos" :src="item.src" :key="index" alt="llll" style="background-color: white">-->
                                                                        <!--                                      </viewer>-->
                                                                    </div>
                                                                    <div class="tR03" v-if="int.photo!='999'">
                                                                        <div style="margin-right: 20px;color: #06A8C9" >
                                                                            <span v-if="NoBuy==1">
                                                                                <el-popover
                                                                                        placement="bottom"
                                                                                        width="200"
                                                                                        trigger="click"
                                                                                        content="您未购买，没有回复权限！">
                                                                                   <span slot="reference"><img src="../../assets/images/Case-07.png" alt="">{{int.counts}}</span>

                                                                                </el-popover>

                                                                            </span>
                                                                            <span v-else @click="changeLisDL(int)"><img src="../../assets/images/Case-07.png" alt="">{{int.counts}}</span>

                                                                        </div>
                                                                        <div v-if="int.isLike==0" style="color:#FA6E86" @click="likeTopicA(int)"><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>
                                                                        <div v-else style="color:#FA6E86" @click="likeTopicB(int)"><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>

                                                                    </div>
                                                                    <div class="tR03" v-else>
                                                                        <div v-if="int.isLike==0" style="color:#FA6E86" @click="likeTopicA(int)"><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>
                                                                        <div v-else style="color:#FA6E86" @click="likeTopicB(int)"><img src="../../assets/images/Case-08.png" alt="">{{int.likes}}</div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="int.list.length>0">
                                                                <div class="Lintlist"  v-for="(Lin,index) in int.list" :key="index">
                                                                    <div class="portrait">
                                                                        <img v-if="Lin.photo=='1'" src="../../assets/images/m1.png" alt="">
                                                                        <img v-if="Lin.photo=='2'" src="../../assets/images/m2.png" alt="">
                                                                        <img v-if="Lin.photo=='3'" src="../../assets/images/m3.png" alt="">
                                                                        <img v-if="Lin.photo=='4'" src="../../assets/images/m4.png" alt="">
                                                                        <img v-if="Lin.photo=='5'" src="../../assets/images/m5.png" alt="">
                                                                    </div>
                                                                    <div class="intR">
                                                                        <div class="tR01">
                                                                            <div class="lf spaceD" :title="Lin.nickName">{{Lin.nickName}}</div>
                                                                            <div class="rg">{{Lin.createTime}}</div>
                                                                        </div>
                                                                        <div class="tR02">
                                                                            <viewer :images="photo">
                                                                                <span v-html="Lin.content"></span>
                                                                                <!--                                      <img v-for="(item,index) in photos" :src="item.src" :key="index" alt="llll" style="background-color: white">-->

                                                                            </viewer>
                                                                        </div>
                                                                        <div class="tR03">
                                                                            <div v-if="Lin.isLike==0" style="color:#FA6E86" @click="likeTopicC(Lin)"><img src="../../assets/images/Case-08.png" alt="">{{Lin.likes}}</div>
                                                                            <div v-else style="color:#FA6E86" @click="likeTopicD(Lin)"><img src="../../assets/images/Case-08.png" alt="">{{Lin.likes}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="answering">
                                        <div class="noDatasF">
                                            <img src="../../assets/images/tw-01.png" alt="">
                                            <p>还没人提问，哈，我来占沙发！</p>
                                            <el-button @click="IwantQuestionObj()" v-if="NoBuy !=1" type="primary" class="Btn-2" style="background-color:#FF9100;border-color:#FF9100;height: 34px;padding: 0 30px" size="mini" round>
                                                我要提问
                                            </el-button>
                                            <el-button v-else disabled type="primary" class="Btn-2" style="background-color:#FF9100;border-color:#FF9100;height: 34px;padding: 0 30px" size="mini" round>
                                                我要提问
                                            </el-button>
                                        </div>
                                    </div>
                                </div>

                                <div class="mod-2-content-itme" v-show="CaseCurA==1">
                                    <div class="exercisesModular">
                                        <div class="top">
                                            <div class="lf" v-if="NoBuy==1">
                                                <div class="paperTypes" v-if="this.paperTypes.length==3">
<!--                                                    statusA:{{statusA}}  statusB:{{statusB}}  statusC:{{statusC}}-->
                                                    <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>
                                                    <li class="activeD">B卷</li>
                                                    <li class="activeD">C卷</li>
                                                </div>
                                                <div class="paperTypes" v-if="this.paperTypes.length==2">
<!--                                                    statusA:{{statusA}}  statusB:{{statusB}}-->
                                                    <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>
                                                    <li class="activeD">B卷</li>
                                                </div>
                                                <div class="paperTypes" v-if="this.paperTypes.length==1">
                                                    <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>
                                                </div>
                                            </div>
                                            <div class="lf" v-else>
                                                <div class="paperTypes" v-if="this.paperTypes.length==3">
                                                    <!--                                                    statusA:{{statusA}}  statusB:{{statusB}}  statusC:{{statusC}}-->
                                                    <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>
                                                    <li @click="paperTypesB()" :class="{activeB:CaseCurB==1}">B卷</li>
                                                    <li @click="paperTypesC()" :class="{activeB:CaseCurB==2}">C卷</li>
                                                </div>
                                                <div class="paperTypes" v-if="this.paperTypes.length==2">
                                                    <!--                                                    statusA:{{statusA}}  statusB:{{statusB}}-->
                                                    <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>
                                                    <li @click="paperTypesB()" :class="{activeB:CaseCurB==1}">B卷</li>
                                                </div>
                                                <div class="paperTypes" v-if="this.paperTypes.length==1">
                                                    <li @click="paperTypesA()" :class="{activeB:CaseCurB==0}">A卷</li>
                                                </div>
                                            </div>

                                            <div class="rg">
                                                <span v-if="TodayCount !=null &&this.status==3" style="padding-right: 20px">共{{TodayCount}}分</span>

                                                <el-button v-if="this.status==2" type="text" style="color:#299B4C">练习题已提交！</el-button>
                                                <el-button v-if="this.status==3" type="text" style="color:#299B4C"> 练习题已批改！</el-button>
                                                <span>
                                                    <el-button v-if="this.status==1&&NoBuy !=1" type="warning" size="small" @click="commitAnaswer()"   round>提交或保存</el-button>
                                                    <el-button v-else type="info" disabled round>提交或保存</el-button>
                                                </span>

                                            </div>
                                        </div>
                                        <div class="topicOr-card">
                                            <div class="card-cod">
                                                <div class="tCart" v-if="this.status==1">
                                                    <div class="st">
                                                        <cace-home-work :homeworks="questionData" :questionDataName="questionDataName" :testPaper="1"></cace-home-work>
                                                    </div>
                                                    <div class="dt">
                                                        <div class="AnswerSheet">
                                                            <div class="Acontent" v-for="(item,i) in formData" :key="i">
                                                                <div class="Aname">{{item.name}}</div>
                                                                <div class="Acn topic" v-for="(items,index) in item.list" :key="index">
                                                                    <!--选择题-->
                                                                    <div v-if="items.type==1">
                                                                        <div class="Alist">
                                                                            <div class="A1">{{items.questionNo}}</div>
                                                                            <div class="A2">
                                                                                <div class="Aoption">

                                                                                    <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                                                                                         @click="changeList(Aoption,Aop, items)"
                                                                                         :class="{active:answers[items.id] ==Aoption}"
                                                                                    >
                                                                                        {{Aoption}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!--duo xuan 题-->
                                                                    <!--                       <div v-if="items.typeName=='选择题'">-->
                                                                    <!--                         <div class="Alist">-->
                                                                    <!--                           <div class="A1">{{items.questionNo}}</div>-->
                                                                    <!--                           <div class="A2">-->
                                                                    <!--                             <div class="Aoption">-->

                                                                    <!--                               <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"-->
                                                                    <!--                                    @click="changeMoreList(Aoption,Aop, items)"-->
                                                                    <!--                                    :class="{active:answers[items.id] && answers[items.id].includes(Aoption)}"-->
                                                                    <!--                               >-->
                                                                    <!--                                 {{Aoption}}-->
                                                                    <!--                               </div>-->
                                                                    <!--                             </div>-->
                                                                    <!--                           </div>-->
                                                                    <!--                         </div>-->
                                                                    <!--                       </div>-->



                                                                    <!--非选择题-->
                                                                    <div v-if="items.type==0">
                                                                        <div class="Alist">
                                                                            <div class="A1" @click="AnswerPad()">{{items.questionNo}}</div>
                                                                            <div class="A2">
                                                                                <div>{{items.isDiscourse}}</div>
                                                                                <div class="Foption">
                                                                                    <!--                               <div class="A-tem" v-for="(Atem,i) in items.empty" :key="i"></div>-->
                                                                                    <!--<vue-ueditor-wrap :config="editorConfig" v-model="answers[items.id]"></vue-ueditor-wrap>-->
                                                                                    <!--预览-->

                                                                                    <!--非语篇-->
                                                                                    <div class="myeditablediv" v-if="NoBuy !=1">
                                                                                        <tinymce
                                                                                                :disabled="disabled==1"
                                                                                                v-model="answers[items.id]"
                                                                                                :questionId="items.id"
                                                                                                :parentId="parseInt(items.parentId)"
                                                                                                :question="questionData"
                                                                                                :content.sync="answers[items.id]"
                                                                                                :params="{id: String(items.id),testPaper:1}"
                                                                                                :stuAnswers="items"
                                                                                                :answerSheets="formData"
                                                                                                @changeStuAnswer="changeStuAnswer"
                                                                                                ref="editor1001"
                                                                                                style="height: auto; border-radius: 22px">
                                                                                        </tinymce>
                                                                                    </div>
                                                                                    <div v-else class="myeditablediv">
                                                                                        <div class="UediWrapA">
                                                                                            <div class="EtorToolbarboxA">
                                                                                                <div class="Etor-01"></div>
                                                                                                <div class="Etor-02">右键下方内容区域图片进行编辑修改</div>
                                                                                            </div>
                                                                                            <div class="EtorToolbarboxB"></div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tCart" v-if="this.status==2">
                                                    <div class="st">
                                                        <cace-home-work :homeworks="questionData"></cace-home-work>
                                                    </div>
                                                    <div class="dt">
                                                        <div class="AnswerSheet">
                                                            <div class="Acontent" v-for="(item,i) in formData" :key="i">
                                                                <div class="Aname">{{item.name}}</div>
                                                                <div class="Acn topic" v-for="(items,index) in item.list" :key="index">
                                                                    <!--选择题-->
                                                                    <div v-if="items.typeName=='选择题'">
                                                                        <div class="Alist">
                                                                            <div class="A1">{{items.questionNo}}</div>
                                                                            <div class="A2">
                                                                                <div class="Aoption">
                                                                                    <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                                                                                         :class="{active:answers[items.id] ==Aoption}"
                                                                                    >
                                                                                        {{Aoption}}
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!--duo xuan 题-->
                                                                    <!--                       <div v-if="items.typeName=='选择题'">-->
                                                                    <!--                         <div class="Alist">-->
                                                                    <!--                           <div class="A1">{{items.questionNo}}</div>-->
                                                                    <!--                           <div class="A2">-->
                                                                    <!--                             <div class="Aoption">-->

                                                                    <!--                               <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"-->
                                                                    <!--                                    @click="changeMoreList(Aoption,Aop, items)"-->
                                                                    <!--                                    :class="{active:answers[items.id] && answers[items.id].includes(Aoption)}"-->
                                                                    <!--                               >-->
                                                                    <!--                                 {{Aoption}}-->
                                                                    <!--                               </div>-->
                                                                    <!--                             </div>-->
                                                                    <!--                           </div>-->
                                                                    <!--                         </div>-->
                                                                    <!--                       </div>-->



                                                                    <!--非选择题-->
                                                                    <div v-if="items.typeName!='选择题'">
                                                                        <div class="Alist">
                                                                            <div class="A1" @click="AnswerPad()">{{items.questionNo}}</div>
                                                                            <div class="A2">
                                                                                <div class="Foption">
                                                                                    <!--                               <div class="A-tem" v-for="(Atem,i) in items.empty" :key="i"></div>-->
                                                                                    <!--  <vue-ueditor-wrap :config="editorConfig" v-model="answers[items.id]"></vue-ueditor-wrap>-->
                                                                                    <!--预览-->
                                                                                    <div class="ueditorWrap" v-if="NoBuy !=1">
                                                                                        <div class="EtorToolbarboxA">
                                                                                            <div class="Etor-01"></div>
                                                                                            <div class="Etor-02"></div>
                                                                                        </div>
                                                                                        <div class="EtorToolbarboxB">
                                                                                            <div class="studentAnswer" v-html="items.studentAnswer"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-else class="ueditorWrap">
                                                                                        <div class="UediWrapA">
                                                                                            <div class="EtorToolbarboxA">
                                                                                                <div class="Etor-01"></div>
                                                                                                <div class="Etor-02">右键下方内容区域图片进行编辑修改</div>
                                                                                            </div>
                                                                                            <div class="EtorToolbarboxB"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!--非语篇-->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tCart" v-if="this.status==3">
                                                    <div class="st">
                                                        <cace-home-work :homeworks="questionData"></cace-home-work>
                                                    </div>
                                                    <div class="dt">
                                                        <div class="AnswerSheet">
                                                            <div class="Acontent" v-for="(item,i) in formData" :key="i">
                                                                <div class="Aname">{{item.name}}</div>
                                                                <div class="Acn topic" v-for="(items,index) in item.list" :key="index">
                                                                    <!--选择题-->
                                                                    <div v-if="items.typeName=='选择题'">
                                                                        <div class="Alist-D" v-if="items.type==1">
                                                                            <div class="Al-A">
                                                                                <div class="A1">{{items.questionNo}}</div>
                                                                                <div class="A2">
                                                                                    <div class="Aoption">
                                                                                        <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                                                                                             :class="{active:items.studentAnswer ==Aoption}"
                                                                                        >
                                                                                            {{Aoption}}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="correct-Box">
                                                                                        <img v-if="items.isRight==1" src="../../assets/images/correct.png" alt="">
                                                                                        <img v-else src="../../assets/images/error.png" alt="">
                                                                                        <span>得分：{{items.studentScore}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!--                  <div class="Al-B" v-if="items.isRight==2">-->
                                                                            <!--                    <div class="D1">-->
                                                                            <!--                      <div class="D1-A1">参考答案:</div>-->
                                                                            <!--                      <div class="D1-A2" v-html="items.questionAnswer">-->

                                                                            <!--                      </div>-->
                                                                            <!--                    </div>-->
                                                                            <!--                  </div>-->
                                                                            <div class="D2" v-if="items.studentAnswer==''">
                                                                                <!--                                        <div class="pb">未答题</div>-->
                                                                            </div>


                                                                        </div>
                                                                    </div>




                                                                    <!--非选择题-->
                                                                    <div v-if="items.typeName!='选择题'">
                                                                        <div class="Blist-E">
                                                                            <div class="A1">{{items.questionNo}}</div>
                                                                            <div class="A2">
                                                                                <div class="Da-1" v-if="items.studentAnswer==''">未做</div>

                                                                                <div class="Da-2" v-else>
                                                                                    <div class="AoptionB" >
                                                                                        <viewer :images="photo">
                                                                                            <span v-html="items.studentAnswer"></span>
                                                                                        </viewer>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="correct-Box">
                                                                                    <img v-if="items.isRight==1" src="../../assets/images/correct.png" alt="">
                                                                                    <img v-if="items.isRight==2" src="../../assets/images/error.png" alt="">
                                                                                    <span>得分：{{items.studentScore}}</span>
                                                                                </div>


                                                                            </div>
                                                                            <div class="A3">
                                                                                <div class="D1" v-if="items.description !=''&&items.description!=null">
                                                                                    <div class="D1-A1">评语:</div>
                                                                                    <div class="D1-A2">{{items.description}}</div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!--展开收起-->
                    <div class="Btn">
                        <div class="BtnA" @click="showQuestion = !showQuestion" v-show="showQuestion"></div>
                        <div class="BtnB" @click="showQuestion = !showQuestion" v-show="!showQuestion"></div>
                    </div>
                </div>
                <div class="area-rg" v-show="showQuestion">
                    <div class="bick-r-01" @click="danYjuObj()">
                        <img src="../../assets/images/lesson.png" alt="">
                    </div>
                    <div class="bick-r-02">
                        <el-form
                                ref="ruleForm"
                                :model="ruleForm"
                                status-icon
                                :rules="rules"
                                label-width=""
                                class="freForm"
                        >
                            <div class="blackName">
                                <img src="../../assets/images/Case-05.png" alt=""> 学历案笔记
                            </div>
                            <div class="note">
                                <div class="nodeBR">
                                    <div class="notecase">
                                        <div class="cName">
                                            <div class="Aam1">线索</div>
                                            <div class="Aam2">
                                                <el-tooltip effect="dark" popper-class="testtooltip" placement="bottom">
                                                    <div slot="content">提炼归纳<br/>重要知识点<br/>帮助回忆的提示……</div>
                                                    <i class="el-icon-question"></i>
                                                </el-tooltip>

                                            </div>
                                        </div>
                                        <div class="cList">
                                            <div class="list">
                                                <div class="LA">

                                                </div>
                                                <div class="LB">
                                                    <div class="mynoteeditablediv" v-if="NoBuy !=1">
                                                        <tinymceNote
                                                                :disabled="disabled==1"
                                                                v-model="ruleForm.note1"
                                                                :noteType="1"
                                                                :notes="ruleForm"
                                                                :params="{id: String(9001)}"
                                                                ref="noteeditor1"
                                                                style="height: auto; border-radius: 22px">
                                                        </tinymceNote>
                                                    </div>
                                                    <div v-else class="mynoteeditablediv">
                                                        <div class="UediWrapB">
                                                            <div class="EtorToolbarboxA">
                                                                <div class="Etor-01"></div>
                                                                <div class="Etor-02">右键下方内容区域图片进行编辑修改</div>
                                                            </div>
                                                            <div class="EtorToolbarboxB"></div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="notecase">
                                        <div class="cName">
                                            <div class="Aam1">笔记内容</div>
                                            <div class="Aam2">
                                                <el-tooltip effect="dark" popper-class="testtooltip" placement="bottom">
                                                    <div slot="content">在这里记录具体的重点内容<br/>使用简洁的文字、符号、缩写来记录<br/>自己的感悟……</div>
                                                    <i class="el-icon-question"></i>
                                                </el-tooltip>
                                            </div>

                                        </div>
                                        <div class="cList">
                                            <div class="list">
                                                <div class="LA">
                                                </div>
                                                <div class="LB">
                                                    <div class="mynoteeditablediv" v-if="NoBuy !=1">
                                                        <tinymceNote :disabled="disabled==1"
                                                                     v-model="ruleForm.note4"
                                                                     :noteType="2"
                                                                     :notes="ruleForm"
                                                                     :params="{id: String(9002)}"
                                                                     ref="noteeditor2"
                                                                     style="height: auto; border-radius: 22px">
                                                        </tinymceNote>
                                                    </div>
                                                    <div v-else class="mynoteeditablediv">
                                                        <div class="UediWrapB">
                                                            <div class="EtorToolbarboxA">
                                                                <div class="Etor-01"></div>
                                                                <div class="Etor-02">右键下方内容区域图片进行编辑修改</div>
                                                            </div>
                                                            <div class="EtorToolbarboxB"></div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="notecase">
                                        <div class="cName">
                                            <div class="Aam1">总结</div>
                                            <div class="Aam2">
                                                <el-tooltip effect="dark" popper-class="testtooltip" placement="bottom">
                                                    <div slot="content">对全部内容的思考与提炼<br/>记录下自己对课程的疑惑，以及后续的解答<br/>补充当时课程上没有记全面的内容<br/>写下与其他相关知识点的链接页码……</div>
                                                    <i class="el-icon-question"></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                        <div class="cList">
                                            <div class="list">
                                                <div class="LA">
                                                </div>
                                                <div class="LB">
                                                    <div class="mynoteeditablediv" v-if="NoBuy !=1">
                                                        <tinymceNote
                                                                :disabled="disabled==1"
                                                                v-model="ruleForm.note7"
                                                                :noteType="3"
                                                                :notes="ruleForm"
                                                                :params="{id: String(9003)}"
                                                                ref="noteeditor3"
                                                                style="height: auto; border-radius: 22px">
                                                        </tinymceNote>
                                                    </div>
                                                    <div v-else class="mynoteeditablediv">
                                                        <div class="UediWrapB">
                                                            <div class="EtorToolbarboxA">
                                                                <div class="Etor-01"></div>
                                                                <div class="Etor-02">右键下方内容区域图片进行编辑修改</div>
                                                            </div>
                                                            <div class="EtorToolbarboxB"></div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="noteBTn" >
                                <el-button :disabled="disabled" type="primary" icon="el-icon-download" size="mini" round
                                           @click="exportStudyNodes()"
                                           class="bTn">
                                    导出成word
                                </el-button>
                                <el-button :disabled="disabled" type="danger" round icon="el-icon-document-checked" size="mini" @click="saveStudyNodesForm('ruleForm')"
                                           class="bTn">保存
                                </el-button>
                            </div>
                        </el-form>
                    </div>


                </div>
            </div>


        </div>
        <!-- 我要提问框-->
        <el-dialog :visible.sync="IwantQuestion" :close-on-click-modal="false" :modal-append-to-body="false" title="我要提问" width="800px" left>
            <div class="dialogDy">
                <div class="dy1">
                    <div class="dy-lf">标题：</div>
                    <div class="dy-rg">
                        <el-input v-model="QuestionFrom.topic"></el-input>
                    </div>
                </div>
                <div class="dy1">
                    <div class="dy-lf">内容：</div>
                    <div class="dy-rg">
                        <!--            <com-ueditor-wrap :content.sync="QuestionFrom.content" :params="{id: 'Q'}"></com-ueditor-wrap>-->
                        <div class="myforumeditablediv"  id="myforumeditablediv">
                            <tinymceForum
                                    v-model="QuestionFrom.content"
                                    :noteType="1"
                                    :notes="QuestionFrom"
                                    :params="{id: String(8001)}"
                                    ref="forumeditor1"
                                    style="height: auto; border-radius: 22px">
                            </tinymceForum>
                        </div>

                    </div>
                </div>


                <!--        <el-input-->
                <!--                v-model="QuestionFrom.topic"-->
                <!--                maxlength="500"-->
                <!--                :rows="8"-->
                <!--                placeholder="请输入"-->
                <!--                show-word-limit-->
                <!--                type="textarea"-->
                <!--        />-->
            </div>
            <template #footer>
                <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">
                    <el-button class="bTn" @click="addTopic()" round type="primary">确定</el-button>
                </div>
            </template>
        </el-dialog>
        <!-- 第一级回复框-->
        <el-dialog :visible.sync="AnswerDialogA" :close-on-click-modal="false" :modal-append-to-body="false"   title="回复讨论" width="800px" left>
            <div class="TipM">
                <el-form
                        ref="AnswerForm"
                        :model="AnswerForm"
                        status-icon
                        :rules="rules"
                        label-width="100px"
                >
                    <div class="AnswerForm">

                        <div class="list">
                            <el-form-item label="发言：" prop="">
                                <viewer :images="photo">
                                    <span v-html="AnswerDialogAData.content"></span>
                                </viewer>
                            </el-form-item>
                        </div>
                        <div class="list">
                            <el-form-item label="昵称：" prop="">
                                <span>{{AnswerDialogAData.nickName}}</span>
                            </el-form-item>
                        </div>
                        <div class="list">
                            <el-form-item label="回复：" prop="">
                                <!--              <com-ueditor-wrap :content.sync="AnswerForm.replyMsg" :params="{id: AnswerDialogAData.id}"></com-ueditor-wrap>-->
                                <!--              <el-input-->
                                <!--                      v-model="AnswerForm.replyMsg"-->
                                <!--                      maxlength="300"-->
                                <!--                      :rows="8"-->
                                <!--                      placeholder="请输入"-->
                                <!--                      show-word-limit-->
                                <!--                      type="textarea"-->
                                <!--              />-->

                                <div class="myforumeditablediv" >
                                    <tinymceForum
                                            v-model="AnswerForm.replyMsg"
                                            :noteType="1"
                                            :notes="AnswerForm"
                                            :params="{id: String(8002)}"
                                            ref="forumeditor2"
                                            style="height: auto; border-radius: 22px">
                                    </tinymceForum>
                                </div>
                            </el-form-item>
                        </div>
                    </div>

                </el-form>
                <div class="Mtn" style="padding-bottom: 20px;box-sizing: border-box">
                    <el-button class="bTn" @click="replyTopic()" round type="primary">确定</el-button>
                </div>
            </div>

        </el-dialog>
        <!-- 第二级回复框-->
        <el-dialog :visible.sync="AnswerDialogB" :close-on-click-modal="false" :modal-append-to-body="false"  title="回复讨论" width="800px" left>
            <el-form
                    ref="AnswerFormB"
                    :model="AnswerFormB"
                    status-icon
                    :rules="rules"
                    label-width="100px"
            >
                <div class="AnswerForm">


                    <div class="list">
                        <el-form-item label="发言：" prop="">
                            <viewer :images="photo" >
                                <span v-html="replyTopicBData.content"></span>
                            </viewer>
                            <!--              <span>{{replyTopicBData.content}}</span>-->
                        </el-form-item>
                    </div>
                    <div class="list">
                        <el-form-item label="昵称：" prop="">
                            <span>{{replyTopicBData.nickName}}</span>
                        </el-form-item>
                    </div>
                    <div class="listBY">
                        <el-form-item label="回复：" prop="">
                            <!--              <com-ueditor-wrap :content.sync="AnswerFormB.replyMsg" :params="{id:AnswerFormB.id}"></com-ueditor-wrap>-->
                            <!--              <el-input-->
                            <!--                      v-model="AnswerFormB.replyMsg"-->
                            <!--                      maxlength="300"-->
                            <!--                      :rows="8"-->
                            <!--                      placeholder="请输入"-->
                            <!--                      show-word-limit-->
                            <!--                      type="textarea"-->
                            <!--              />-->

                            <div class="myforumeditablediv">
                                <tinymceForum
                                        v-model="AnswerFormB.replyMsg"
                                        :noteType="1"
                                        :notes="AnswerFormB"
                                        :params="{id: String(8003)}"
                                        ref="forumeditor3"
                                        style="height: auto; border-radius: 22px">
                                </tinymceForum>
                            </div>

                        </el-form-item>
                    </div>
                </div>

            </el-form>
            <template #footer>
                <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">
                    <el-button @click="chaListBDL()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>
                </div>
            </template>
        </el-dialog>
        <!-- 我要提问框-->
        <el-dialog :visible.sync="isshowImg" :close-on-click-modal="false" :modal-append-to-body='false'  title="图片预览" width="90%" left>
            <div class="dialogHd">
                <img :src="'data:image/png;base64,'+imGurl" style="width: 80%" />
            </div>
        </el-dialog>
        <!-- 未答题提示框-->
        <el-dialog :visible.sync="AnswerDialogF" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="800px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>您未答题</h2>
                    <p>不能做无效操作！请答题</p>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" @click="AnswerDialogObJ()" round  type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 您未答完题，确认提示框-->
        <el-dialog :visible.sync="AnswerDialogG" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>还有试题未做</h2>
                    <p>是否提交？或继续答题</p>
                    <div class="R">提交后，系统将开始进行测评</div>
                </div>
                <div class="Mtn">
                    <el-button round class="bTn" @click="SubmitsaveAnaswer()" type="primary">仅保存</el-button>
                    <el-button class="bTn" @click="SubmitHomework()" plain round  type="warning">直接提交</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 您已做完题，确认提示框-->
        <el-dialog :visible.sync="AnswerDialogH" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交试卷答案" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <p>是否提交本次试卷答案？</p>
                    <div class="R">提交后，系统将开始进行测评</div>
                </div>
                <div class="Mtn">
                    <el-button round class="bTn" @click="SubmitsaveAnaswer()" type="primary">仅保存</el-button>
                    <el-button class="bTn" @click="SubmitHomework()" plain round  type="warning">直接提交</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 作业状态=确认提示框-->
        <el-dialog :visible.sync="statusDialogA" :close-on-click-modal="false" :modal-append-to-body='false'  title="提示" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>请提交A卷后，再使用B卷</h2>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" round @click="CloesstatusDialogA()" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="statusDialogB" :close-on-click-modal="false" :modal-append-to-body='false'  title="提示" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2 >请提交B卷后，再使用C卷</h2>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" round @click="CloesstatusDialogB()" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- A卷答案保存，确认提示框-->
        <el-dialog :visible.sync="saveDialogA" :close-on-click-modal="false" :modal-append-to-body='false'  title="保存试题答案" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>A卷已保存成功</h2>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" round @click="CloesaveAnaswerA()" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- B卷答案保存，确认提示框-->
        <el-dialog :visible.sync="saveDialogB" :close-on-click-modal="false" :modal-append-to-body='false'  title="保存试题答案" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>B卷已保存成功</h2>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" round @click="CloesaveAnaswerB()" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- C卷答案保存，确认提示框-->
        <el-dialog :visible.sync="saveDialogC" :close-on-click-modal="false" :modal-append-to-body='false'  title="保存试题答案" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>C卷已保存成功</h2>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" round  @click="CloesaveAnaswerC()" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- C卷提示，确认提示框-->
        <el-dialog :visible.sync="SnswerTC" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>恭喜，试卷已经全部做完！</h2>
                    <p>提交后，系统讲进行测评</p>
                </div>
                <div class="Mtn">
                    <el-button class="bTn" @click="SnswerTBtn2()" round type="primary">返回</el-button>
                    <el-button class="bTn" @click="toEvaluation()" plain round  type="warning">查看测评</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="AnswerDialogJ" :close-on-click-modal="false" :modal-append-to-body='false'  title="提示" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>还有试题未做</h2>
                    <p>是否要返回上一级？</p>
                </div>
                <div class="Mtn">
<!--                    <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswerA()" type="primary">仅保存</el-button>-->
                    <el-button round class="bTn" @click="BackSubmitsave()" type="primary">确定</el-button>
                    <el-button round class="bTn" @click="gB2()" type="primary">取消</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="AnswerDialogY" :close-on-click-modal="false" :modal-append-to-body='false'  title="提示" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>还有试题未做</h2>
                    <p>是否要进入单元卷？</p>
                </div>
                <div class="Mtn">
<!--                    <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswerA()" type="primary">仅保存</el-button>-->
                    <el-button class="bTn" round @click="checkFolderBuyAll()" type="primary">确定</el-button>
                    <el-button class="bTn" round @click="gB1()" type="primary">取消</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog
                title=""
                :visible.sync="FolderBuyAllDialog"
                :modal-append-to-body='false'
                width="600px"
                class="buyDialogA1"
        >
            <div class="buyDialogT">
                <i class="el-icon-warning"></i>
            </div>
            <div class="buyDialogA1-01">
                <p>想做单元测试</p>
                <p>请购买本单元所有的学历案！</p>
            </div>

            <div class="buyDialogA1-03 cursor" @click="FolderBuyAll()">
                确定
            </div>
        </el-dialog>

        <!-- 未购买跳转，确认提示框-->
        <el-dialog :visible.sync="NoResisBuyDialog" :close-on-click-modal="false" :modal-append-to-body='false'  title="购买提示" width="500px" left>
            <div class="TipM">
                <div class="Msg">
                    <h2>还有试题未做</h2>
                    <p>是否提交？或继续答题</p>
                    <div class="R">提交后，系统将开始进行测评</div>
                </div>
                <div class="Mtn">
                    <el-button round class="bTn" @click="SubmitsaveAnaswer()" type="primary">仅保存</el-button>
                    <el-button class="bTn" @click="SubmitHomework()" plain round  type="warning">直接提交</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import { mapState } from 'vuex'
    import caceHomeWork from "../../components/caceHomeWorkA";
    import tinymce from "../../components/tinymce/index";
    import tinymceNote from "../../components/tinymce/noteIndex";
    import tinymceForum from "../../components/tinymce/forumIndex";
    import $ from 'jquery';
    // import ComUeditorWrap from "../../components/editorStu/ComUeditorWrap";
    // import ComUeditorWrapNote1 from "../../components/editorNote/ComUeditorWrapNote1";
    //  import ComUeditorWrapNote4 from "../../components/editorNote/ComUeditorWrapNote4";
    // import ComUeditorWrapNote7 from "../../components/editorNote/ComUeditorWrapNote7";
    import {queryXlaDetailV2Obj,queryXlaImgObj,checkFolderBuyAllObj,changeQusObj,commitAnaswerObj,addTopicObj,TopicListObj,TopicReplyListObj,replyTopicObj,
        queryStudyNodesObj,
        saveAnaswerObj,
        saveStudyNodesObj,
        exportStudyNodesObj,
        likeTopicObj} from '@/api/details'
    import Editor from "@tinymce/tinymce-vue";   //路径
    export default {
        name: '',
        data() {
            return {
                dlog1:null,
                dlog2:null,
                questionDataName:'',
                statusA:null,
                statusB:null,
                statusC:null,
                catalogue:null,
                journal:null,
                orderCur:null,
                isMobile:null,
                queryResData:{},
                statusDialogA:false,
                statusDialogB:false,
                TodayCount:null,
                wTlistData:{},
                TopicReplyListChange:{},
                queryXlaDetail:{},
                photo:null,
                photos:[],
                NoResisBuyDialog:false,
                loading: false,
                status:null,//1: 未提交 ： 2 ： 已提交 ： 3 ：已批改
                CaseCurA:false,
                CaseCurB:false,
                bottomShow: true,
                SnswerTA:false,
                SnswerTB:false,
                SnswerTC:false,
                saveDialogA:false,
                saveDialogB:false,
                saveDialogC:false,
                qType:null,
                disabled:null,
                always:1,//1为播放，2 为暂停
                alwaysName:"播放",//1为播放，2 为暂停
                interval:'300',//图片播放时长
                FolderBuyAllDialog:false,
                AnswerDialogA:false,//第一级回复
                AnswerDialogB:false,//第二级回复
                AnswerDialogF:false,//未答题提示框
                AnswerDialogG:false,//您未答完题，确认提交
                AnswerDialogH:false,//您已做完题，确认提交
                AnswerDialogAData:{},
                AnswerDialogJ:false,//点击返回未答完题，确认保存
                replyTopicBData:{},//第二级
                AnswerPadDialog:false,//答题写字板弹框
                value: null,
                NodesId:null,
                likeTopicData:{},
                likeT:0,

                currentPage:0,
                pageSize:10,
                total:null,
                picImgSrc: {},
                portraitList: [
                    {id:1, url: require('../../assets/images/m1.png')},
                    {id:2, url: require('../../assets/images/m2.png')},
                    {id:3, url: require('../../assets/images/m3.png')},
                    {id:4, url: require('../../assets/images/m4.png')},
                    {id:5, url: require('../../assets/images/m5.png')},
                ],
                timeData:[
                    {
                        id:2,
                        name:'2000'
                    },
                    {
                        id:4,
                        name:'4000'
                    },
                    {
                        id:6,
                        name:'6000'
                    },
                    {
                        id:8,
                        name:'8000'
                    },
                    {
                        id:30,
                        name:'30000'
                    },
                    {
                        id:60,
                        name:'60000'
                    },
                    {
                        id:120,
                        name:'120000'
                    },
                ],

                options:{
                    isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                    lastWriteWidth: 2,  //下笔的宽度 [Number] 可选
                    lineCap: 'round',   //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
                    lineJoin: 'round',  //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
                    canvasWidth: 1200, //canvas宽高 [Number] 可选
                    canvasHeight: 600,  //高度  [Number] 可选
                    isShowBorder: true, //是否显示边框 [可选]   当签名模式处于false的时候此选项才生效
                    bgColor: '#E8F7FE', //背景色 [String] 可选
                    borderWidth: 1, // 网格线宽度  [Number] 可选
                    borderColor: "#AFE6FF", //网格颜色  [String] 可选
                    writeWidth: 2, //基础轨迹宽度  [Number] 可选
                    maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
                    minWriteWidth: 2, // 写字模式最小线宽  [Number] 可选
                    writeColor: '#101010', // 轨迹颜色  [String] 可选
                    imgType:'png'   //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
                },


                ruleForm:{
                    note1: '',
                    note2: '',
                    note3: '',
                    note4: '',
                    note5: '',
                    note6: '',
                    note7: '',
                    note8: '',
                    note9: '',
                    note10: '',
                },
                AnswerForm:{
                    replyMsg: '',

                },
                AnswerFormB:{
                    replyMsg: '',
                },
                rules: {

                    nickName: [
                        {
                            required: true,
                            message: '请输入昵称',
                            trigger: 'blur',
                        },
                    ],
                },
                ResisBuy:null,
                le2:null,
                cur: 0, //默认选中第一个tab
                repA:0,
                index:0,
                isLike3:null,
                topicId2:null,
                detailQus:1,
                NoBuy:null,
                editorConfig:{
                    // 你的UEditor资源存放的路径,相对于打包后的index.html
                    //UEDITOR_HOME_URL: "/UEditor/",
                    //UEDITOR_HOME_URL: '/ueditor/',
                    //serverUrl: process.env.VUE_APP_BASE_URL + "/study/baiduEditor",
                    // 编辑器不自动被内容撑高
                    autoHeightEnabled: false,
                    // 初始容器高度
                    initialFrameHeight: 300,
                    // 初始容器宽度
                    initialFrameWidth: "100%",
                    // 关闭自动保存
                    enableAutoSave: false,
                    // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                },
                qus:null,
                setNote1:'',
                setNote4:'',
                setNote7:'',
                type: null,
                html1: '',
                content: '',
                answers: {}, // 选择题答题内容
                isactive: false,
                imGurl:'',
                paperTypes:[],
                questionData:[],// 试题
                formData:[],// 答题卡
                current:null,
                currentNum:'',
                cid:null,
                AnswerDialogY:false,
                isshowImg:false,
                IwantQuestion:false,//我要提问-弹框
                TopicListData:[],//论坛主题列表
                TcReplyLData:[],//论坛主题回复列表
                queryStudyNodesData:{},//学历案笔记数据
                isHas: 0 ,
                level:null,
                query:{
                    id:null
                },
                prolisFrom:{
                    replyMsg:''
                },
                QuestionFrom:{
                    content:'',
                    topic:'',
                    canvas:null
                },
                showQuestion: true,//展开收起
                indexPic: 0,
                heightpx: '320px',

                picList:[

                ],
                picListCarousel:[],


                currentIndex: 0, // 选项卡当前的索引
                saveTopicId:null,
                noMore: false,//没有更多了

/////////////
                editorOption: {
                    placeholder: "",
                    modules: {
                        toolbar: { // 菜单栏（工具栏），手动配置自己需要的富文本功能
                            container: [
                                /* ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
                [{ indent: "-1" }, { indent: "+1" }], // 左右缩进
                [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
                [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
                [{ align: [] }], //对齐方式*/
                                ["image", "video"], //上传图片、上传视频
                            ],
                            handlers: {
                                'image': function (value) { // 劫持图片上传，自定义图片上传
                                    console.log('...................');
                                    if (value) {
                                        // 通过input的type=file唤醒选择弹框，选择之后自定义上传路径
                                        //document.querySelector('#input').click()
                                        console.log('value', value)
                                        document.querySelector('.quill-upload .el-icon-upload').click()


                                        // let quill =  this.$refs.myQuillEditor.quill;
                                        // if (quill == null) {
                                        //    console.log("11   quill si null ") ;
                                        // } else {
                                        //
                                        //   console.log(' quill is not null ');
                                        // }


                                    } else {
                                        this.quill.format('image', false);
                                    }

                                }
                            }
                        },
                        imageResize:{ // 图片缩放比例
                            displayStyles:{
                                backgroundColor:'black',
                                border:'none',
                                color:'white'
                            },
                            modules:['Resize','DisplaySize', 'Toolbar'] // Resize 允许缩放， DisplaySize 缩放时显示像素 Toolbar 显示工具栏
                        }
                    }
                },
////////
            }
        },
        created() {

            this.EducationCase = this.$route.query.EducationCase;
            this.clickFlag=this.$route.query.clickFlag;
            this.bookId = this.$route.query.bookId;
            this.folderId = this.$route.query.folderId;
            this.disabled = this.$route.query.disabled;
            this.type = this.$route.query.type;
            this.ResisBuy = this.$route.query.ResisBuy;
            this.totalPrice=this.$route.query.totalPrice;
            this.totalDiscount=this.$route.query.totalDiscount;
            this.journal=this.$route.query.journal;
            this.orderCur=this.$route.query.orderCur;
            this.catalogue=this.$route.query.catalogue;

            this.id = this.$route.query.id;
            this.NoBuy= this.$route.query.NoBuy;
            this.stu = this.$route.query.stu
            this.token= localStorage.getItem('Authorization')
            this.setNote1A=sessionStorage.getItem("setNote1")

            this.queryXlaImg()
            //this.queryRes()
            this.TopicList()
            this.queryStudyNodes()
            this.timeObj()
            this.paperTypesA()

            let bridge = this;  // 2022.05.05
            window.showImg = bridge.showImg;  // 2022.05.05

            window.showImgAlt = bridge.showImgAlt;  // 2022.05.06
            window.hiddenImgAlt = bridge.hiddenImgAlt;  // 2022.05.06

        },
        mounted() {
            this.questionType = 1
            this.type=1
            if (this._isMobile()) {
                this.isMobile=1
                // 移动端
            } else {
                this.isMobile=2
                // PC端
            }
        },
        components: {
            tinymce,
            tinymceNote,
            tinymceForum,
            caceHomeWork,
            //  ComUeditorWrap,
            // ComUeditorWrapNote1,
            //ComUeditorWrapNote4,
            // ComUeditorWrapNote7
        },

        methods: {
            _isMobile() {

                let flag = navigator.userAgent.match(

                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i

                );

                return flag;

            },
            showImgAlt:function(event,obj) {

                //console.log(' over ' + event.offsetX  );
                // this.$nextTick(()=>{
                var tooltip='<div id="alttip">'+'双击预览图片'+'</div>';
                var y = event.clientY;
                var x = event.clientX;
                $(tooltip).css({background:'#dde17d',position:'absolute', height:'30px',width:'120px',top:y+'px',left:x+'px'}).appendTo('body');
                // })

            },
            hiddenImgAlt:function(event,obj) {
                // console.log(' out ' + obj.id )
                // this.$nextTick(() =>{
                $('#alttip').remove();
                // })

            },

            showImg:function(id){  // 2022.05.05

                this.photos = [];
                let images = $('body').find("img");

                for (let img  of images) {
                    let imgId = img.getAttribute("id");
                    if (imgId != null && imgId === id ) {
                        let tmpImg = {
                            id: '',
                            src: ''
                        }
                        tmpImg.id = img.getAttribute("id");
                        tmpImg.src = img.getAttribute("src");
                        this.photos.push(tmpImg);
                    }
                }

                this.$viewerApi({
                    options: {
                        initialViewIndex: 0
                    },
                    images: this.photos,
                })

            },

            getCurrentTime() {
                //获取当前时间并打印
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth()+1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
                let time = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
                return time;

            },

            changeStuAnswer(stuAnswer) {
                if (this.formData) {
                    for (let i = 0 ; i < this.formData.length; i ++) {
                        let item = this.formData[i];
                        let isFind = 0;
                        for (let j = 0 ; j < item.list.length; j ++) {
                            let qu = item.list[j];
                            let quId = qu.id;
                            if (Number(quId) == Number(stuAnswer.id)) {
                                //this.$set(this.answers,quId,qu) ;
                                // qu.studentAnswer = '';
                                // qu.studentAnswer = editContent;
                                qu.canvas = stuAnswer.canvas;
                                isFind = 1;
                                break;
                            }
                        }
                        if (isFind == 1) {
                            break;
                        }
                    }
                }

            },

            getContent(content) {
                this.content = content
            },

            // 失去焦点
            onEditorBlur(editor) {},
            // 获得焦点
            onEditorFocus(editor) {},
            // 开始
            onEditorReady(editor) {},
            // 值发生变化
            onEditorChange(editor) {
                debugger
                this.content = editor.html;
                console.log(editor);
            },

            quillSuccess(response) {
                //let that = this;
                if(response){
                    // 获取文本编辑器
                    //  debugger
                    const quill = this.editor1; //  that.$refs.myQuillEditor.quill;
                    if (quill == undefined ||   quill == null   ) {
                        console.log("quill is null ......... . ");
                        return;
                    }

                    // 获取光标位置
                    const pos=quill.getSelection().index
                    // 插入图片到光标位置
                    let url = 'https://pics7.baidu.com/feed/a6efce1b9d16fdfa50fd11fa2fcf9f5e95ee7bd8.jpeg?token=c5b2d42df687c01dc9130409e9f5f91a';
                    quill.insertEmbed(pos,'image',url)
                    // 调整光标到最后
                    quill.setSelection(pos + 1)
                }else{
                    this.$message.error('图片插入失败')
                }
            },

            CaseCurObjA() {
                this.type=1
                this.CaseCurA=0
                this.statusA=null
                this.statusB=null
                this.statusC=null
                this.CaseCurB=0

                let questionIdList = Object.keys(this.answers)
                let values = Object.values(this.answers)
                let isNull = false //是否有未答得题目
                let answerList = questionIdList.map(item => {
                    if (!this.answers[item] && values.some(it => it !== '')) {
                        isNull = true
                    }
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                if(values.every(it => it === '')) {
                    // 您未答题提醒
                    console.log('您未答题123')
                }else if(isNull){
                    // 您未答完题
                    console.log('您未答完题456')
                }

                this.SubmitsaveAnaswerA01()
            },
            CaseCurObjB() {
                this.CaseCurA=1
                this.statusA=null
                this.statusB=null
                this.statusC=null
                this.CaseCurB=0
                //this.SubmitsaveAnaswerA01()
                this.queryRes()
            },
            paperTypesA() {
                this.type=1
                //this.SubmitsaveAnaswerA01()
                //this.answers= {}
                this.CaseCurB=0
                if(this.statusA ==1) {
                    this.type=1

                    this.queryRes()
                } else {
                    this.type=1
                    this.CaseCurB=0
                    this.queryRes()
                    console.log('A卷已经提交')
                }
            },
            paperTypesB() {
                this.type=2
                this.SubmitsaveAnaswerA01()
                if(this.statusA ==1) {
                    this.type=1
                    this.statusDialogA=true
                    console.log('A卷没有提交')
                } else {
                    this.CaseCurB=1
                    this.queryRes()
                    console.log('A卷已经提交55')
                }
            },

            paperTypesC() {
                this.type=3
                this.SubmitsaveAnaswerA01()
                if(this.statusA ==1) {
                    this.type=1
                    console.log('点C卷的时候，提示B卷未提交')
                    this.CaseCurB=0
                    this.statusDialogA=true
                }else {
                    if(this.statusB !=1) {
                        this.type=3
                        this.CaseCurB=2
                        this.queryRes()
                        console.log('点C卷的时候，提示B卷已经提交提交，加载C卷内容')
                    } else {
                        this.type=2
                        this.statusDialogB=true
                        console.log(this.statusB,'点C卷的时候，提示B卷已经提交提交，加载C卷内容999')

                    }
                    // this.type=2
                    // this.CaseCurB=0
                    // this.statusDialogB=true
                }
            },
            dyback() {
                if(this.ResisBuy==1) {
                    this.$router.go(-1);
                }else {
                    this.NoResisBuyDialog = true
                }
            },
            //返回
            back() {
                this.$router.go(-1);
            },
            opENClass () {
                location.href = 'LearningSystem://class'
            },


            alwaysA() {
                this.always=1
                this.bottomShow = !this.bottomShow;
                if(this.bottomShow){
                    this.alwaysName = '播放'
                }else{
                    this.alwaysName = '暂停'
                }
            },
            alwaysB() {
                this.always=2
            },
            changeUeditor (val, params) {
                console.log(params.id,'00000000')
                this.$set(this.answers, params.id, val)
                // console.log("------------      " + val);
                //console.log(this.answers,'00')
            },
            // changeUeditorQ (val, params) {
            //   console.log(params.id,'00000000')
            //   this.$set(this.answers, params.id, val)
            //   console.log(this.answers,'00')
            // },
            /**
             * 清除画板
             */
            canvasClear(){
                this.$refs.SignCanvas.canvasClear();
            },

            /**
             * 保存图片
             */
            saveAsImg(){
                const img = this.$refs.SignCanvas.saveAsImg();
                alert(`image 的base64：${img}`);
            },

            /**
             * 下载图片
             */
            downloadSignImg(){
                this.$refs.SignCanvas.downloadSignImg();
            },
            AnswerPad() {
                this.AnswerPadDialog = true
            },

            timeObj() {
                this.query.id = this.timeData[3].id
                this.interval = this.timeData[3].name
            },






            changeCarousel (count) {
                this.$refs['scroll'].wrap.scrollTop = count == 0 ? 0 : 110 * count - 50 //想滚到哪个高度，就写多少
                this.indexPic = count
                if ((this.picList.length - count) < 5 && !this.noMore) {
                    this.queryXlaImg()
                }
            },
            reset () {
                this.answers = []
                this.refresh()
            },
            download () {
                window.location.href = "https://apk-1257051889.cos.ap-shanghai.myqcloud.com/download/%E5%AD%A6%E5%8E%86%E6%A1%88.exe";
            },
            getEditorValue (value, q) {
                console.log(value, q, 'value, q')
            },

            //查询学习笔记
            queryStudyNodes() {
                // console.log(99999999999999)
                this.dlog2= 1
                let obj = {
                    resourceId: this.id,
                }
                queryStudyNodesObj(obj).then((res) => { // eslint-disable-line no-unused-vars
                    // this.ruleForm.note1 = res.data.note1
                    // this.ruleForm.note2 = res.data.note2
                    // this.ruleForm.note3 = res.data.note3
                    // this.ruleForm.note4 = res.data.note4
                    // this.ruleForm.note5 = res.data.note5
                    // this.ruleForm.note6 = res.data.note6
                    // this.ruleForm.note7 = res.data.note7
                    // this.ruleForm.note8 = res.data.note8
                    // this.ruleForm.note9 = res.data.note9
                    // this.ruleForm.note10 = res.data.note10
                    if(res.code==200) {
                        this.dlog2= null
                        this.queryStudyNodesData=res.data
                        if (res.data != null && res.data.note1 != undefined ) {
                            this.$set( this.ruleForm, 'note1', res.data.note1)
                            this.$set( this.ruleForm, 'note2', res.data.note2)
                            this.$set( this.ruleForm, 'note3', res.data.note3)
                            this.$set( this.ruleForm, 'note4', res.data.note4)
                            this.$set( this.ruleForm, 'note5', res.data.note5)
                            this.$set( this.ruleForm, 'note6', res.data.note6)
                            this.$set( this.ruleForm, 'note7', res.data.note7)
                            this.$set( this.ruleForm, 'note8', res.data.note8)
                            this.$set( this.ruleForm, 'note9', res.data.note9)
                            this.$set( this.ruleForm, 'note10', res.data.note10)
                        }
                    }


                })
            },
            getNotes(value, params) {
                console.log(value,params, '--')
                this.$set( this.ruleForm, params.id, value)
            },
            //提交学习笔记
            saveStudyNodesForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        let lu = {
                            resourceId: this.id,
                        }
                        let obj = {
                            note1: this.ruleForm.note1,
                            note2: this.ruleForm.note2,
                            note4: this.ruleForm.note4,
                            note5: this.ruleForm.note5,
                            note7: this.ruleForm.note7,
                            note8: this.ruleForm.note8,
                        }
                        saveStudyNodesObj(lu,obj).then((res) => { // eslint-disable-line no-unused-vars
                            if (res.code == 200) {
                                this.NodesId= res.data
                                // console.log(this.NodesId,'88383')
                                this.queryStudyNodes()
                                // this.message = res.message
                                // this.$message.success(this.message);

                            } else {
                                this.message = res.message
                                this.$message.warning(this.message);
                            }
                        })
                    }
                })

            },
            FolderBuyAll() {
                this.FolderBuyAllDialog=false
            },
            //检查目录下学历案是否已全购买
            checkFolderBuyAll() {
                this.AnswerDialogY = false
                let lu = {
                    bookId: this.bookId,
                    folderId: this.folderId,
                }
                checkFolderBuyAllObj(lu).then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        console.log(res.data,'88383')
                        this.$router.push({
                            path: "/mainb/UnitTestQuestions",
                            query: {
                                EducationCase: this.EducationCase,
                                clickFlag: this.clickFlag,
                                bookId: this.bookId,
                                stu: this.stu,
                                folderId: res.data,

                            }
                        });

                    } else {
                        this.FolderBuyAllDialog=true
                        // this.message = res.message
                        // this.$message.warning(this.message);
                    }
                })

            },
            gB1() {
                this.AnswerDialogY = false
            },
            danYjuObj() {

                let questionIdList = Object.keys(this.answers)
                let values = Object.values(this.answers)
                let isNull = false //是否有未答得题目
                //console.log(questionIdList.length ,this.quesSum,'shit')

                let answerList = questionIdList.map(item => {
                    if (!this.answers[item] && values.some(it => it !== '')) {
                        isNull = true
                    }
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                // 当作业状态不等于1，直接跳到单元卷
                if (this.status != 1) {
                    this.checkFolderBuyAll()
                } else {
                    if(values.every(it => it === '')) {
                        // 您未答题提醒
                        console.log('未答题')
                        this.checkFolderBuyAll()
                    }else if(isNull){
                        // 您未答完题，确认提交吗
                        this.AnswerDialogY=true
                    }else {
                        console.log('已经答完题，再次')
                        this.checkFolderBuyAll()
                    }
                }

            },
            objN() {
                window.open(process.env.VUE_APP_BASE_URL +`/study/students/exportStudyNodes/?resourceId=${this.NodesId}&token=${this.token}`, "_blank");

            },
            //导出学习笔记
            exportStudyNodes() {
                let lu = {
                    resourceId: this.id,
                }
                let obj = {
                    note1:this.ruleForm.note1,
                    note2:this.ruleForm.note2,
                    note3:this.ruleForm.note3,
                    note4:this.ruleForm.note4,
                    note5:this.ruleForm.note5,
                    note6:this.ruleForm.note6,
                    note7:this.ruleForm.note7,
                    note8:this.ruleForm.note8,
                    note9:this.ruleForm.note9,
                    note10:this.ruleForm.note10,
                }
                saveStudyNodesObj(lu,obj).then((res) => { // eslint-disable-line no-unused-vars
                    this.NodesId= res.data
                    this.objN()
                })
            },
            // exportStudyNodes() {
            //   let obj = {
            //     resourceId: this.id,
            //   }
            //   exportStudyNodesObj(obj).then((res) => { // eslint-disable-line no-unused-vars
            //     console.log(res,'900000')
            //     let blob = new Blob([res.data],{ type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'});
            //     let objectUrl = URL.createObjectURL(blob);
            //     window.location.href = objectUrl;
            //   })
            // },
            //
            queryXlaImg() {
                if (this.noMore) {
                    return
                }
                if (this.loading) {
                    return;
                }
                console.log(this.loading,this.noMore,  '----------------this.loading')
                this.loading = true;
                if(this.disabled==1) {
                    this.dlog1= 1
                    console.log('这是试用')
                    let obj = {
                        id: this.id,
                    }

                    let data = {
                        pageCurrent: this.currentPage,//初始页
                        pageSize: this.pageSize,//每页的数据
                    }
                    queryXlaImgObj(obj,data).then((res) => { // eslint-disable-line no-unused-vars

                        if(res.code==200) {
                            this.dlog1= null
                            this.loading = false
                            if (!res.data.records.length) {
                                this.noMore = true
                            }
                            this.picList = res.data.records
                            console.log(res,'939')
                            let count = -1
                            for(let i of this.picList){
                                count+=1
                                i.uid= count
                            }
                            // this.pageSize = 10;
                            // this.currentPage = 1;
                            this.total =10
                            this.$nextTick(() => {
                                this.setActiveItem(this.indexPic)
                            })
                        }


                    })
                } else {
                    console.log('这是非试用')
                    this.dlog1= 1
                    let obj = {
                        id: this.id,
                    }
                    this.currentPage += 1
                    this.loading = false
                    let data = {
                        pageCurrent: this.currentPage,//初始页
                        pageSize: this.pageSize,//每页的数据
                    }
                    queryXlaImgObj(obj,data).then((res) => { // eslint-disable-line no-unused-vars
                        console.log(res.data,'939')
                        if(res.code==200) {
                            this.dlog1= null
                            if (!res.data.records.length) {
                                this.noMore = true
                            }
                            this.picList = [...this.picList, ...res.data.records ]

                            if(this.NoBuy==1) {
                                let count = -1
                                for(let i of this.picList){
                                    count+=1
                                    i.uid= count
                                }
                                // this.pageSize = res.data.size;
                                // this.currentPage = res.data.current;
                                this.total = 10
                                this.$nextTick(() => {
                                    this.setActiveItem(this.indexPic)
                                })
                            } else {
                                this.picList = res.data.records
                                console.log(this.picList,'学历案图片89')
                                let count = -1
                                for(let i of this.picList){
                                    count+=1
                                    i.uid= count
                                }
                                // this.pageSize = res.data.size;
                                // this.currentPage = res.data.current;
                                this.total = res.data.total
                                this.$nextTick(() => {
                                    this.setActiveItem(this.indexPic)
                                })
                            }
                        }



                    })
                }

            },
            handleSizeChange(val) {
                this.pagesize = val;
                this.indexPic=0
                this.queryXlaImg();
            },
            handleCurrentChange(val) {
                this.indexPic=0
                this.currentPage = val;
                // this.setContextData("currentPage8", this.currentPage);
                // 刷新页面内容
                this.queryXlaImg();
            },
            //学历案详情和试题
            queryRes() {
                this.answers= {}
                this.formData = [];
                // const loading = this.$loading({
                //     lock: true,
                //     text: '请耐心等待，正在加载中...',
                //     spinner: 'el-icon-loading',
                //     background: 'rgba(0, 0, 0, 0.7)'
                // });
                let obj = {
                    bookId: this.bookId,
                    folderId: this.folderId,
                    id: this.id,
                    type:this.type
                }
                queryXlaDetailV2Obj(obj).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        //loading.close();
                        if (!res.data) {
                            this.$store.commit('changeType', 1)
                            return
                        }
                        if (res.data.paperTypes?.[0] != this.questionTyp) {
                            this.$store.commit('changeType', res.data.paperTypes?.[0])
                        }

                        let count = -1
                        for(let i of this.picList){
                            count+=1
                            i.uid= count
                        }
                        //this.picList = res.data.imgs
                        this.queryResData = res.data
                        this.status = this.queryResData.status  // 1: 未提交 ： 2 ： 已提交 ： 3 ：已批改
                        this.paperTypes = this.queryResData.paperTypes
                        this.qus = this.queryResData.qus
                        this.paperId = this.queryResData.qus[0].id
                        this.questionId = this.queryResData.qus[0].id
                        this.type = this.queryResData.qus[0].type
                        this.questionDataName = this.queryResData.qus[0].name
                        this.questionData = this.queryResData.qus[0].question
                        this.formData = this.queryResData.qus[0].answerSheet
                        console.log(this.questionDataName,'83999339')
                        if(this.type==1) {
                            console.log(this.status,'这是A卷熟悉')
                            this.statusA=this.status
                        } else if(this.type==2) {
                            this.statusB=this.status
                            console.log(this.status,'这是B卷属性')
                        } else {
                            this.statusC=this.status
                            console.log(this.status,'这是C卷属性')
                        }
                        this.queryResData.qus[0].answerSheet.map(item => {
                            if (item.list != null) {
                                item.list.map(it => {
                                    // console.log(it, 'item')
                                    if (it.isDiscourse == 1) {
                                        if (it.contentMap.discourseList != null) {
                                            it.contentMap.discourseList.map(i => {
                                                // i.content = JSON.parse(i.content)


                                                this.$set(this.answers, i.id, i.studentAnswer)
                                            })
                                        }
                                    } else {
                                        // it.content = JSON.parse(it.content)
                                        this.TodayCount += it.studentScore;


                                        this.$set(this.answers, it.id, it.studentAnswer)
                                    }

                                })
                            }
                        })
                        console.log(this.TodayCount, '非语篇分数')

                        let questionsSum = 0;
                        this.formData.forEach((item) => {
                            //遍历prodAllPrice这个字段，并累加
                            questionsSum += item.list.length;
                        });
                        //返回
                        this.quesSum = questionsSum;
                        console.log(this.quesSum,'数量')


                        this.$nextTick(() => {
                            this.setActiveItem(this.indexPic)
                        })
                    } else {
                        //loading.close();
                        this.queryResData = null
                        this.status = null  // 1: 未提交 ： 2 ： 已提交 ： 3 ：已批改
                        this.paperTypes = null
                        this.qus = null
                        this.paperId = null
                        this.questionId = null
                        this.type = null
                        this.questionData = null
                        this.formData = null
                    }


                })

            },


            //我要提问-增加论坛主题
            addTopic() {
                var bookId = this.bookId
                var folderId = this.folderId
                var id = this.id
                let obj = {
                    topic: this.QuestionFrom.topic,
                    content: this.QuestionFrom.content,
                }
                addTopicObj(obj,bookId,folderId,id).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        sessionStorage.removeItem('TopicReplyListChange')
                        sessionStorage.removeItem('topicIdChange')
                        sessionStorage.removeItem('curChange')
                        this.QuestionFrom.topic = ''
                        this.IwantQuestion = false
                        this.TopicList()
                        this.TopicReplyList()

                    }else {
                        this.$message({
                            message: res.message,
                            type: 'warning'
                        });
                    }
                })
            },
            imageChgange(){
                console.log('783833838338')
            },
            //问题(或主题)列表
            TopicList() {
                this.TopicListData = [];//论坛主题列表
                this.TcReplyLData = [];//论坛主题回复列表
                var bookId = this.bookId
                var folderId = this.folderId
                var id = this.id
                let obj = {

                }

                TopicListObj(obj,bookId,folderId,id).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.TopicListData = res.data
                        this.TopicReplyList()

                    }
                })
            },
            //点击获取问题(或主题)列表
            changeTopicList(index,item) {
                sessionStorage.setItem('curChange',index)
                this.cur=sessionStorage.getItem('curChange')
                this.wTlistData = {};
                this.TcReplyLData = [];//论坛主题回复列表
                this.le2=1
                this.wTlistData=item;
                this.photos = [];

                // if (this.wTlistData.content != null) {
                //   this.setImages();
                // }
                this.isHas = 0 ;
                //this.topicId = item.id
                sessionStorage.setItem('topicIdChange',item.id)
                sessionStorage.setItem('TopicReplyListChange',JSON.stringify(item))

                // 获取本地存储的左侧点击数据
                this.topicId = item.id

                //刷新-聊天列表-第一级
                this.TopicReplyList()
            },
            setImages(){
                if (this.wTlistData.content != null && this.wTlistData.content != '') {
                    var images = $(this.wTlistData.content).find("img");
                    for (let img  of images) {
                        let tmpImg = {
                            src:   '',
                            style: ''
                        }
                        tmpImg.src = img.getAttribute("src");
                        tmpImg.style = "background-color: white;";
                        //console.log('.........................' + img.getAttribute("style")) ; //获取值;
                        this.photos.push(tmpImg);
                    }
                }

            },
            //聊天列表-第一级
            TopicReplyList() {
                this.isHas = 0 ;
                if(sessionStorage.getItem('curChange')!=undefined &&sessionStorage.getItem('curChange')!='') {
                    this.cur = sessionStorage.getItem('curChange')
                } else {
                    this.cur =0
                }
                // 获取本地存储的左侧点击数据
                this.TopicReplyListChange = JSON.parse(window.sessionStorage.getItem('TopicReplyListChange'))
                if(this.TopicReplyListChange!=undefined &&this.TopicReplyListChange!='') {
                    this.topicId = this.TopicReplyListChange.id
                    this.TcReplyLData = this.TopicReplyListChange
                } else {
                    this.topicId = this.TopicListData[0].id
                    this.TopicReplyListChange=this.TopicListData[0]
                    this.cur =0
                }
                this.TcReplyLData = [];//论坛主题回复列表

                let obj = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:this.topicId,
                }
                TopicReplyListObj(obj).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        if (res.data.length > 0 ) {
                            this.TcReplyLData = res.data
                        } else {
                            this.isHas = 1 ;
                            this.TcReplyLData.push({
                                id: 123,
                                name: 'zzzz'
                            })
                        }
                        console.log(this.TcReplyLData,'论坛主题回复列表')
                    }
                })


            },

            //回复
            hf(index,item) {
                this.currentIndex = index;
                this.level = item.level
                this.AnswerDialogA=true
                this.AnswerDialogAData=item
                this.changeTopicList(index,item)
                let obj = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:this.topicId,
                }
                console.log(item.topic,'993994')
            },
            //回复论坛主题
            replyTopic() {
                let lu = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:this.topicId,
                }
                let obj = {
                    replyMsg: this.AnswerForm.replyMsg,
                    level:1
                }
                replyTopicObj(lu,obj).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                        this.AnswerForm.replyMsg= ''
                        this.AnswerDialogA=false
                        this.TopicReplyListData = res.data
                        //this.TopicReplyList()
                        this.TopicList()//问题(或主题)列表 第一级
                        let obj1 = {
                            bookId:this.bookId,
                            folderId:this.folderId,
                            id:this.id,
                            topicId:this.topicId,
                        }
                        TopicReplyListObj(obj1).then((res) => { // eslint-disable-line no-unused-vars
                            // debugger
                            if(res.code==200) {
                                if (res.data.length > 0 ) {
                                    this.TcReplyLData = res.data
                                } else {
                                    this.isHas = 1 ;
                                    this.TcReplyLData.push({
                                        id: 123,
                                        name: 'zzzz'
                                    })
                                }
                                console.log(this.TcReplyLData,'论坛主题回复列表')
                            }
                        })
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            //点击选中背景颜色，拿到该行数据
            changeTopicListB(idx) {
                this.index=idx
            },
            // 点击打开回复弹框
            changeLisDL(int) {
                this.replyTopicBData= int
                this.AnswerDialogB=true
            },
            chaListBDL() {
                this.TopicList() //刷新问题(或主题)列表
                let lu = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:this.replyTopicBData.id,
                }
                let obj = {
                    replyMsg: this.AnswerFormB.replyMsg,
                    level:2
                }
                replyTopicObj(lu,obj).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                        this.AnswerFormB.replyMsg= ''
                        this.AnswerDialogB=false
                        this.TopicReplyListData = res.data

                        console.log('8889000----')
                        //this.TopicReplyList()
                        this.TopicList()//问题(或主题)列表 第一级
                    } else {
                        this.$message.error(res.message);
                    }
                })
                //this.TopicReplyList()
            },
            //回复论坛主题
            replyTopicB() {
                let lu = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:this.topicId,
                }
                let obj = {
                    replyMsg: this.AnswerForm.replyMsg,

                    level:1
                }
                replyTopicObj(lu,obj).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                        this.AnswerForm.replyMsg= ''
                        this.AnswerDialogA=false
                        this.TopicReplyListData = res.data
                        this.TopicReplyList()
                        this.TopicList()//问题(或主题)列表 第一级
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            replyTopic2() {
                let lu = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:this.topicId2,
                }
                let obj = {
                    replyMsg: this.prolisFrom.replyMsg,
                    level:2
                }
                replyTopicObj(lu,obj).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.TopicReplyListData = res.data
                        this.TopicReplyList()
                        this.replyTopic()
                        this.TopicList()
                    }
                })
            },
            changeList(items,Aop, parent){
                this.currentNum = parent.questionNo
                this.current = Aop;//this指向app      :class="{active:Aop==current}
                this.cid = items.id;//this指向app
                this.$set(this.answers, parent.id, items)
                // this.answers[parent.id] = items
                console.log(this.answers, '选中的选项')
            },
            changeMoreList(items,Aop, parent){
                this.currentNum = parent.questionNo
                this.current = Aop;//this指向app      :class="{active:Aop==current}
                this.cid = items.id;//this指向app
                if (!this.answers[parent.id]) {
                    this.$set(this.answers, parent.id, [])
                }
                let idx = this.answers[parent.id].findIndex(it => it === items)
                if (idx == -1) {
                    this.answers[parent.id].push(items)
                } else {
                    this.answers[parent.id].splice(idx, 1)
                }
                console.log(this.answers, '选中的选项')

            },
            //点赞
            likeTopicA(int) {
                if(this.disabled==1) {
                    this.$message({
                        message: '您未购买，没有点赞权限！',
                        type: 'warning'
                    });
                }else {
                    this.likeT=0
                    let lu = {
                        bookId:this.bookId,
                        folderId:this.folderId,
                        id:this.id,
                        topicId:int.id,
                        isLike:0
                    }
                    likeTopicObj(lu).then((res) => { // eslint-disable-line no-unused-vars
                        if(res.code==200) {
                            this.likeTopicData= res.data
                            // this.$message({
                            //   message: res.message,
                            //   type: 'success'
                            // });
                            this.TopicReplyList()
                        }else {
                            this.$message({
                                message: res.message,
                                type: 'error'
                            });
                        }
                    })
                }

            },
            likeTopicB(int) {
                if(this.disabled==1) {
                    this.$message({
                        message: '您未购买，没有点赞权限！',
                        type: 'warning'
                    });
                }else {
                    this.likeT=1
                    let lu = {
                        bookId:this.bookId,
                        folderId:this.folderId,
                        id:this.id,
                        topicId:int.id,
                        isLike:1
                    }
                    likeTopicObj(lu).then((res) => { // eslint-disable-line no-unused-vars
                        if(res.code==200) {
                            this.likeTopicData= res.data
                            // this.$message({
                            //   message: res.message,
                            //   type: 'success'
                            // });
                            this.TopicReplyList()
                        }else {
                            this.$message({
                                message: res.message,
                                type: 'error'
                            });
                        }
                    })
                }

            },
            likeTopicC(Lin) {
                if(this.disabled==1) {
                    this.$message({
                        message: '您未购买，没有点赞权限！',
                        type: 'warning'
                    });
                }else {
                    this.likeT=0
                    let lu = {
                        bookId:this.bookId,
                        folderId:this.folderId,
                        id:this.id,
                        topicId:Lin.id,
                        isLike:0
                    }
                    likeTopicObj(lu).then((res) => { // eslint-disable-line no-unused-vars
                        if(res.code==200) {
                            this.likeTopicData= res.data
                            // this.$message({
                            //   message: res.message,
                            //   type: 'success'
                            // });
                            this.TopicReplyList()
                        }else {
                            this.$message({
                                message: res.message,
                                type: 'error'
                            });
                        }
                    })
                }

            },
            likeTopicD(Lin) {
                this.likeT=1
                let lu = {
                    bookId:this.bookId,
                    folderId:this.folderId,
                    id:this.id,
                    topicId:Lin.id,
                    isLike:1
                }
                likeTopicObj(lu).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.likeTopicData= res.data
                        // this.$message({
                        //   message: res.message,
                        //   type: 'success'
                        // });
                        this.TopicReplyList()
                    }else {
                        this.$message({
                            message: res.message,
                            type: 'error'
                        });
                    }
                })
            },
            // 时间
            timeChang(id){
                let obj = {};
                obj = this.timeData.find((item)=>{//model就是上面的数据源
                    return item.id === id;//筛选出匹配数据
                });
                this.interval = obj.name
                this.query.id=obj.id
                console.log(this.interval,'this.interval')
                console.log(obj.name,'obj.name')
                console.log(obj.id,'obj.id')
            },
            // 清除时间
            timeClear() {
                sessionStorage.removeItem('coursewareYear');
                this.StuBooksObj()
            },
            // 关闭未答题框
            AnswerDialogObJ() {
                this.AnswerDialogF=false
            },
            // 学历案日志
            toJournal() {
                this.$router.push({
                    path: "/mainb/journal",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: 1,
                        stu: this.stu,
                    }
                });
            },
            toOrder() {
                this.$router.push({
                    path: "/mainb/order",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: this.clickFlag,
                        stu: this.stu,
                        orderCur:this.orderCur
                    }
                });
            },
            // 未做完返回
            backA() {
                // if(this.ruleForm.note1.length !=this.queryStudyNodesData.note1.length && this.ruleForm.note4.length !=this.queryStudyNodesData.note4.length&& this.ruleForm.note7.length !=this.queryStudyNodesData.note7.length ){
                //   console.log('学历案笔记1有变化')
                //   // this.saveStudyNodesForm()
                //   this.saveStudyNodesForm('ruleForm')
                // }
                if(this.ruleForm.note1 !=''&&this.ruleForm.note4 !=''&&this.ruleForm.note7 !='') {
                    this.saveStudyNodesForm('ruleForm') // 保存学历案笔记
                }


                // if (this.status != 1 && this.catalogue ==1) {
                //     this.ToCatalogue()
                //     return
                // }
                // if(this.journal ==1) {
                //     this.toJournal()
                // }
                // if(this.orderCur ==1) {
                //     this.toOrder()
                // }

                let questionIdList = Object.keys(this.answers)
                let values = Object.values(this.answers)
                let isNull = false //是否有未答得题目
                //console.log(questionIdList.length ,this.quesSum,'shit')

                let answerList = questionIdList.map(item => {
                    if (!this.answers[item] && values.some(it => it !== '')) {
                        isNull = true
                    }
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                console.log(sessionStorage.getItem("setNote1"),'90')
                if(values.every(it => it === '')) {
                    // 您未答题提醒
                    console.log('未答题')
                    this.SubmitsaveAnaswerBack()
                    if(this.catalogue ==1) {
                        this.ToCatalogue()
                    }
                    if(this.journal ==1) {
                        this.toJournal()
                    }
                    if(this.orderCur ==1) {
                        this.toOrder()
                    }

                }else if(isNull){
                    // 您未答完题，确认提交吗
                    if(this.catalogue ==1) {
                        this.AnswerDialogJ=true
                    }
                    if(this.journal ==1) {
                        this.AnswerDialogJ=true
                    }
                    if(this.orderCur ==1) {
                        this.AnswerDialogJ=true
                    }

                }else {
                    console.log('已经答完题，再次')
                    this.SubmitsaveAnaswerBack()
                    if(this.catalogue ==1) {
                        this.ToCatalogue()
                    }
                    if(this.journal ==1) {
                        this.toJournal()
                    }
                    if(this.orderCur ==1) {
                        this.toOrder()
                    }
                    // let obj = {
                    //   answer: this.answers,
                    //   questionId:this.questionId
                    // }
                    //this.SubmitHomework()
                }
            },
            //提交作业
            commitAnaswer() {
                // this.paperTypes.forEach((item,index)=>{
                //   // if(item==1){
                //   //   console.log(item,'900')
                //   // }
                // })
                var paperId = this.paperId
                // console.log(this.formData,'8888888888')
                // console.log(this.answers,'shit')
                let questionIdList = Object.keys(this.answers)
                let values = Object.values(this.answers)
                let isNull = false //是否有未答得题目
                console.log(questionIdList.length ,this.quesSum,'shit')

                let answerList = questionIdList.map(item => {
                    if (!this.answers[item] && values.some(it => it !== '')) {
                        isNull = true
                    }
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                // 未答题提示
                if(values.every(it => it === '')) {
                    // 您未答题提醒
                    this.AnswerDialogF=true
                }else if(isNull){
                    // 您未答完题，确认提交吗
                    this.AnswerDialogG=true
                }else {
                    this.AnswerDialogH=true
                    // let obj = {
                    //   answer: this.answers,
                    //   questionId:this.questionId
                    // }
                    //this.SubmitHomework()
                }
            },
            SnswerTBtn1() {
                this.$store.commit('changeType', this.questionType + 1)
                console.log(this.questionType,'88282')
                this.reset()
                this.answers={}



            },
            SnswerTBtn2() {
                this.back()
                // this.reload()
                this.reset()
            },
            SnswerTBtn3() {
                this.answers={}
                this.type=3
                this.back()
                this.reset()
            },

            SubmitHomework() {
                // this.paperTypes.forEach((item,index)=>{
                //   if(item==1){
                //     console.log(item,'900')
                //   }
                // })
                const loading = this.$loading({
                    lock: true,
                    text: '请耐心等待，正在提交中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                var paperId = this.paperId
                let questionIdList = Object.keys(this.answers)
                let answerList = questionIdList.map(item => {
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                //设置 画布坐标信息
                answerList = this.setCanvasForAnswerList(answerList);
                commitAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        loading.close();
                        if(this.type==1) {
                            this.AnswerDialogG=false
                            this.AnswerDialogH=false
                            this.CaseCurB=0
                            console.log('这是A卷提交')
                            this.queryRes() //刷试题和答题卡
                            this.answers={}
                        } else if(this.type==2) {
                            this.AnswerDialogG=false
                            this.AnswerDialogH=false
                            //this.CaseCurB=1
                            console.log('这是B卷提交')
                            this.answers={}
                            this.queryRes() //刷试题和答题卡
                        }else if(this.type==3) {
                            this.AnswerDialogG=false
                            this.AnswerDialogH=false
                            //this.CaseCurB=2
                            //this.CaseCurB=1
                            this.queryRes() //刷试题和答题卡
                        } else {
                            console.log('题已经答完')
                            this.SnswerTC=true
                        }
                    }
                })

            },
            // 仅保存
            SubmitsaveAnaswer() {

                var paperId = this.paperId
                let questionIdList = Object.keys(this.answers)
                let answerList = questionIdList.map(item => {
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                //设置 画布坐标信息
                answerList = this.setCanvasForAnswerList(answerList);

                saveAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
                    this.AnswerDialogH=false
                    if(this.type==1) {
                        this.saveDialogA=true
                        this.AnswerDialogG=false
                        this.answers={}
                    }else if(this.type==2) {
                        this.SnswerTA=false
                        this.saveDialogB=true
                        this.answers={}
                    }else if(this.type==3) {
                        this.SnswerTB=false
                        this.AnswerDialogG=false
                        this.saveDialogC=true
                    }
                    this.queryRes()

                })

            },

            // 静默仅保存
            SubmitsaveAnaswerA01() {
                var paperId = this.paperId
                let questionIdList = Object.keys(this.answers)
                let answerList = questionIdList.map(item => {
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                //设置 画布坐标信息
                answerList = this.setCanvasForAnswerList(answerList);
                saveAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
                    if(res.code==200) {
                        this.AnswerDialogH=false
                    }

                    //this.queryRes()

                })

            },
            CloesstatusDialogA() {
               this.statusDialogA=false
               this.SubmitsaveAnaswerA01()
            },
            CloesstatusDialogB() {
                this.statusDialogB=false
                this.SubmitsaveAnaswerA01()
            },

            setCanvasForAnswerList(answerList) {
                for (let i = 0 ; i < this.formData.length ; i ++ ) {
                    let data = this.formData[i];
                    for (let j = 0 ; j < data.list.length; j ++) {
                        let subData = data.list[j];
                        for (let k = 0 ; k < answerList.length; k ++) {
                            let answer = answerList[k];
                            if (answer.questionId == subData.id ) {
                                if (subData.canvas != null && subData.canvas != '') {
                                    answer.canvas = subData.canvas;
                                    //console.log('subData.studentAnswer  ', subData.studentAnswer);
                                    //answer.answer = subData.studentAnswer;
                                }
                            }
                        }
                    }
                }
                return answerList;
            },
            // 跳转到目录
            ToCatalogue() {
                this.$router.push({
                    path: "/mainb/catalogue",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: this.clickFlag,
                        bookId: this.bookId,
                        stu: this.stu,
                        totalPrice:this.totalPrice,
                        totalDiscount:this.totalDiscount,
                    }
                });
            },
            SubmitsaveAnaswerA() {
                this.AnswerDialogY=false
                // this.paperTypes.forEach((item,index)=>{
                //   if(item==1){
                //     console.log(item,'900')
                //   }
                // })
                this.saveStudyNodesForm('ruleForm')
                var paperId = this.paperId
                let questionIdList = Object.keys(this.answers)
                let answerList = questionIdList.map(item => {
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                //设置 画布坐标信息
                answerList = this.setCanvasForAnswerList(answerList);
                saveAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
                    if(this.type==1) {
                        this.AnswerDialogJ=false
                        this.back()
                        this.answers={}
                    }else if(this.type==2) {
                        this.AnswerDialogJ=false
                        this.back()
                        this.answers={}
                    }else if(this.type==3) {
                        this.AnswerDialogJ=false
                        this.back()
                    }
                    this.queryRes()
                })

            },
            SubmitsaveAnaswerD() {
                this.AnswerDialogY=false
                // this.paperTypes.forEach((item,index)=>{
                //   if(item==1){
                //     console.log(item,'900')
                //   }
                // })
                this.saveStudyNodesForm('ruleForm')
                var paperId = this.paperId
                let questionIdList = Object.keys(this.answers)
                let answerList = questionIdList.map(item => {
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                //设置 画布坐标信息
                answerList = this.setCanvasForAnswerList(answerList);
                saveAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
                    if(this.type==1) {
                        this.AnswerDialogJ=false
                        this.answers={}
                    }else if(this.type==2) {
                        this.AnswerDialogJ=false
                        this.answers={}
                    }else if(this.type==3) {
                        this.AnswerDialogJ=false
                    }
                    this.queryRes()
                })

            },
            BackSubmitsave() {
                // 保存
                this.SubmitsaveAnaswerD()
                if(this.catalogue ==1) {
                    this.ToCatalogue()
                }
                if(this.journal ==1) {
                    this.toJournal()
                }
                if(this.orderCur ==1) {
                    this.toOrder()
                }


            },
            gB2() {
                this.AnswerDialogJ=false
            },
            SubmitsaveAnaswerBack() {

                this.saveStudyNodesForm('ruleForm')
                var paperId = this.paperId
                let questionIdList = Object.keys(this.answers)
                let answerList = questionIdList.map(item => {
                    return {
                        questionId: Number(item),
                        answer: this.answers[item]
                    }
                })
                //设置 画布坐标信息
                answerList = this.setCanvasForAnswerList(answerList);
                saveAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars

                })

            },
            // 关闭
            CloesaveAnaswerA() {
                this.AnswerDialogG=false  // 还有试题未做弹框关闭
                this.saveDialogA=false //A卷答案保存关闭
            },
            CloesaveAnaswerB() {
                this.AnswerDialogG=false  // 还有试题未做弹框关闭
                this.saveDialogB=false //B卷答案保存关闭
            },
            CloesaveAnaswerC() {
                this.AnswerDialogG=false  // 还有试题未做弹框关闭
                this.saveDialogC=false //C卷答案保存关闭
            },
            CloeSubmitHomework() {
                this.AnswerDialogG=false
            },
            ingObj(item) {
                console.log(item.id,'8888')
                this.imGurl = item.base64
                this.isshowImg = true
            },



            setActiveItem (i) {
                // console.log(i,"ccccccccccccccc")
                if (this.$refs.carousel) {
                    this.$refs.carousel.setActiveItem(i)
                }
            },

            clickItem (item, index) {
                // console.log(item.uid,"dddddddddddddddddd")
                this.indexPic = item.uid
                // let apicDom = document.getElementsByClassName('pic-item')[0]
                // console.log(apicDom.scrollHeight, this.$refs.scroll.$refs.wrap.scrollTop)
                // let domScrollTop = apicDom * index
                // if (domScrollTop > this.$refs.scroll.$refs.wrap.scrollHeight) {
                //   domScrollTop = this.$refs.scroll.$refs.wrap.scrollHeight
                // }
                // this.$refs.scroll.$refs.wrap.scrollTop = domScrollTop
                // console.log(this.$refs.scroll.$refs.wrap.scrollTop)

                this.setActiveItem(item.uid)
            },
            tabChange(val) {
                this.activeIndex = val;
                this.$router.push({   //新增一个参数
                    query:{tabsid:val}
                });

            },
            //查询列表
            listObj() {
                this.listData = this.list
            },
            //我要提问-弹框
            IwantQuestionObj() {
                this.QuestionFrom.topic = '';
                this.QuestionFrom.content = '';
                this.IwantQuestion = true
            },
            //去知识内容测评
            toEvaluation() {
                this.$router.push({
                    path: "/mainb/evaluation",
                    query: {
                        EducationCase:this.EducationCase,
                        stu:this.stu,
                        clickFlag:2
                    }
                });
            },
            //去单元实战演练
            toUnitTest() {
                this.$router.push({
                    path: "/mainb/UnitTestQuestions",
                    query: {
                        EducationCase:this.EducationCase,
                        bookId:this.bookId,
                        folderId:this.folderId,
                        id:this.id,
                        disabled:this.disabled,
                        stu:this.stu,
                        clickFlag:0
                    }
                });
            }
        },
        watch: {
            // questionType: {
            //     handler: function() {
            //         this.queryRes()
            //     },
            // },
            'TopicListObj' : {
                handler : function(newVal,oldVal){
                    this.TopicLnewVal=newVal
                    console.log(newVal,'变化后的值',)
                }
            },
            'TopicList' : {
                handler : function(newVal,oldVal){
                    this.TopicListData=newVal
                    console.log(newVal,'变化后的值',)
                }
            },
            // 详情试题监听
            'queryResData' : {
                handler : function(newVal){
                    this.queryResData=newVal
                }
            },
            // 问题(或主题)列表监听
            'TopicListData' : {
                handler : function(newVal){
                    this.TopicListData=newVal
                }
            },
            // 聊天列表-第一级监听
            'TcReplyLData' : {
                handler : function(newVal){
                    this.TcReplyLData=newVal
                }
            },
            statusA: {
                handler(newName, oldName) {
                    // ...
                },
                immediate: true
            },
            statusB: {
                handler(newName, oldName) {
                    // ...
                },
                immediate: true
            },
            statusC: {
                handler(newName, oldName) {
                    // ...
                },
                immediate: true
            },



            // 'likeTopicA' : {
            //   handler : function(newVal,oldVal){
            //     this.likeTopicData=newVal
            //     console.log(newVal,'变化后的值',)
            //   }
            // },
            // 'TopicReplyList' : {
            //   handler : function(newVal,oldVal){
            //     this.TcReplyLData=newVal
            //     console.log(newVal,'变化后的值',)
            //   }
            // }

        },
        computed:{
            ...mapState([
                // 'questionType',
                'StuByUserData',
            ]),
            disabledNoMore () {
                return this.loading || this.noMore
            },
            isFinish () {
                if (this.wTlistData.length > 0) {
                    return false
                } else {
                    return true
                }

            },
            editor1() {
                return this.$refs.myQuillEditor.quill;
            }

        },


    }

</script>
<style lang="scss" scoped>
    .caseLayout {
        width: 100%;
        height: 1480px;
        padding: 0px 0px 0px 0px;
        margin-bottom: 20px;
        box-sizing: border-box;
        .dialogHd {
            img{
                margin: auto;
            }
        }
        .details-A {
            width: 100%;
            margin: auto;
            display: flex;
            .area-lf {
                width: 75%;
                float: left;
                position: relative;



                .col_l_main {
                    width: 100%;
                    .mod-1 {
                        width: 100%;
                        padding: 10px 0px;
                        background-color: #D5F3FF;
                        border-bottom: 5px solid #6ED7FF;
                        .Hd {
                            width: 98%;
                            margin:auto;
                            padding: 10px 0px;

                            box-sizing: border-box;
                            border-bottom: 1px solid #9EE3FF;
                            display: flex;

                            .lf {
                                width: 30%;
                                font-weight: bold;
                                margin-top: 8px;
                                justify-content: flex-start;
                                align-items: center;
                                img {
                                    vertical-align: middle;
                                }


                            }
                            .bc{
                                width: 30%;
                                justify-content: center;
                                display: flex;

                                .dtY {
                                    height: 28px;
                                    padding: 4px 10px;
                                    background: rgba(255, 255, 255, 0.9);
                                    border: 1px solid #ddd;
                                    opacity: 1;
                                    border-radius: 30px;
                                    position: relative;
                                    .Btn-1 {
                                        width: 60px
                                    }
                                    .Btn-2 {
                                        width: 70px;
                                        height: 32px;
                                        padding: 0;
                                        vertical-align: middle;
                                        margin-top: -4px;
                                    }
                                    .Btn-3 {
                                        height: 32px;
                                        padding: 0;
                                        vertical-align: middle;
                                        margin-top: -2px;
                                    }
                                    ::v-deep.el-input__inner {
                                        height: 28px;
                                        line-height: 28px;
                                    }
                                    ::v-deep.el-select .el-input .el-select__caret {
                                        color: #C0C4CC;
                                        margin-top: 6px;
                                    }
                                }
                                .dtYPag {
                                    height: 36px;
                                    line-height: 36px;
                                    color: #666;
                                    padding: 4px 30px;
                                    opacity: 1;
                                }
                            }

                            .rg {
                                width: 40%;
                                justify-content: flex-end;
                                display: flex;
                                text-align: right;
                                .rF {
                                    height: 32px;
                                }
                                .k {
                                    padding-left: 30px;
                                    height: 32px;
                                    background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 7px;
                                    background-size: 16px;
                                    color: #fff;
                                    border: 1px solid #06A8C9;

                                }
                            }
                        }
                        // 轮播图片区域
                        .focus_PiC {
                            width: 100%;
                            padding: 10px 50px 0 50px;
                            box-sizing: border-box;
                            margin: auto;
                            .scrollList{
                                width:100%;
                                display: flex;
                                .Pic-left{
                                    width:260px;
                                    height: 448px;
                                    /*overflow: auto;*/
                                    justify-content: flex-start;
                                    .scrollbar{
                                    }
                                    ::v-deep.el-scrollbar {
                                        //height: 100%;
                                        height: 448px;
                                    }
                                    ::v-deep.el-scrollbar__wrap {
                                        //overflow: hidden;
                                        //width: 100%;
                                        //height: 120%;
                                    }
                                    ::v-deep .infinite-list-wrapper {
                                        height: 100%;
                                        overflow: auto;
                                        .el-scrollbar{
                                            height: 100%;
                                        }
                                        .el-scrollbar__bar{
                                            &.is-vertical{
                                                width:10px;//滚动条宽度
                                            }
                                        }
                                        .el-scrollbar__wrap{
                                            overflow-y: auto;
                                            overflow-x:hidden;
                                        }
                                        .el-scrollbar__thumb {
                                            ////可设置滚动条颜色
                                            background-color: #278dfd;
                                        }
                                    }
                                }
                                .Pic-right {
                                    width: 90%;
                                    ::v-deep.el-carousel__container {
                                        height: 436px;
                                        text-align: center;
                                    }
                                    ::v-deep.el-carousel__item {
                                        background: url("../../assets/images/pic-bj.png") no-repeat;
                                        background-size: cover;
                                    }
                                    img{

                                        height: 436px;
                                        margin: auto;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                    .mod-2 {
                        width: 100%;
                        height: 770px;
                        padding: 10px 0px;
                        background-color: #FFF;
                        .Hd{
                            width: 98%;
                            height: 40px;
                            border-bottom: 2px solid #eee;
                            margin: 10px auto;
                            li{
                                float: left;
                                height: 40px;
                                line-height:40px;
                                padding: 0px 20px;
                                text-align: center;
                                margin-right: 10px;
                                font-weight: bold;
                                color: #000;
                                cursor: pointer;
                                border-radius: 6px 6px 0px 0px;
                                img {
                                    height: 28px;
                                    vertical-align: middle;
                                    margin-left: 6px;
                                }
                            }
                            .activeA{
                                background-color: #D1EEFE;
                                border-bottom: 2px solid #37AEFF;
                                color: #000;
                            }
                        }
                        .mod-2-content {
                            width: 100%;
                            margin: 10px auto 0 auto;
                            .mod-2-content-itme {
                                width: 98%;
                                margin: auto;

                                .paperTypes{
                                    width: 98%;
                                    height: 36px;
                                    margin: 10px auto;
                                    span {

                                    }
                                    li{
                                        float: left;
                                        width: 60px;
                                        height: 30px;
                                        line-height:30px;
                                        padding: 0px 0;
                                        text-align: center;
                                        margin-right: 10px;
                                        border: 1px solid #CCC;
                                        font-weight: bold;
                                        color: #000;
                                        cursor: pointer;
                                        border-radius: 30px;
                                        img {
                                            vertical-align: middle;
                                            margin-left: 6px;
                                        }
                                    }
                                    .activeB{
                                        border: 1px solid #06A8C9;
                                        background-color: #06A8C9;
                                        color: #FFF;
                                    }
                                    .activeD{
                                        border: 1px solid #CCCCCC;
                                        background-color: #CCCCCC;
                                        color: #FFF;
                                    }
                                }

                                /*交互答疑*/
                                .answering {
                                    width: 100%;
                                    margin: auto auto 0px auto;
                                    background-color: #fff;
                                    .noDatasF {
                                        width: 600px;
                                        margin: 100px auto;
                                        text-align: center;
                                        img {
                                            width: 292px;
                                            margin: auto;
                                        }
                                        p {
                                            line-height: 50px;
                                        }
                                    }
                                    .top {
                                        width: 98%;
                                        height: 34px;
                                        line-height: 24px;
                                        margin: 10px auto;
                                        box-sizing: border-box;
                                        display: flex;
                                        justify-content: flex-end;
                                        .bTn {
                                            height: 32px;
                                        }
                                    }
                                    .answeringQuestion {
                                        width: 100%;
                                        height: 674px;
                                        margin: 10px auto 0 auto;
                                        display: flex;
                                        .answering-lf {
                                            width: 30%;
                                            height: 650px;
                                            overflow-y: auto;
                                            .prolist {
                                                box-sizing: border-box;
                                                padding: 10px 0 0 0;
                                                float: left;
                                                width: 100%;
                                                padding-bottom: 6px;
                                                padding-left: 10px;
                                                box-sizing: border-box;
                                                border-right: 1px solid #ddd;
                                                border-bottom: 1px solid #ddd;
                                                list-style: none;
                                                text-align: left;
                                                .ansBtnA {
                                                    height: 24px;
                                                    padding: 0px 10px;
                                                }
                                                .pl01 {
                                                    width: 96%;
                                                    line-height: 20px;
                                                    margin: auto;
                                                    display: flex;
                                                    .pA {
                                                        width: 70%;
                                                        line-height: 24px;
                                                    }
                                                    .pB {
                                                        width: 30%;
                                                        text-align: right;
                                                        .ansBtn0 {
                                                            border: 1px solid #299B4C; background-color: #299B4C; color: #fff
                                                        }
                                                    }
                                                }
                                                .pl02 {
                                                    width: 96%;
                                                    line-height: 36px;
                                                    margin: auto;
                                                    display: flex;
                                                    .pA {
                                                        width: 20%;
                                                        justify-content: flex-start;
                                                        color: #999;
                                                    }
                                                    .pB {
                                                        width: 45%;
                                                        justify-content: center;
                                                        color: #999;
                                                    }
                                                    .pC {
                                                        width: 35%;
                                                        justify-content: flex-end;
                                                        color: #1A86FF;
                                                        text-align: right;
                                                    }
                                                }
                                            }
                                            .activeAnswering {
                                                color: #0090DA;
                                                border-left: 4px solid #1A86FF;
                                                border-right: 1px solid #E8F7FE;
                                                background: #E8F7FE;
                                                opacity: 1;
                                                .pl01 {
                                                    width: 96%;
                                                    line-height: 20px;
                                                    margin: auto;
                                                    display: flex;
                                                    .pA {
                                                        color: #0090DA;
                                                        font-weight: bold;
                                                    }
                                                    .pB {
                                                        text-align: right;
                                                    }
                                                }
                                            }
                                        }
                                        .answering-rg {
                                            width: 70%;
                                            background-color: #E8F7FE;
                                            /* 聊天列表 */
                                            .lt-twwt {
                                                padding: 0px 10px;
                                                box-sizing: border-box;
                                                border-radius: 6px;
                                                border: 1px solid #FF5A00;
                                                margin: 20px auto;
                                                overflow-y: auto;
                                                position: relative;
                                                .Tip {
                                                    position: absolute;
                                                    width: 110px;
                                                    height: 24px;
                                                    line-height: 24px;
                                                    text-align: center;
                                                    color: #FF5A00;
                                                    font-weight: bold;
                                                    right: 0;
                                                    background: #FFE8DC;
                                                    opacity: 1;
                                                    border-radius: 0px 10px 0px 10px;
                                                }
                                                .TntToplist {
                                                    width: 98%;
                                                    margin: auto;
                                                    padding: 10px 10px;
                                                    box-sizing: border-box;
                                                    display: flex;
                                                    border-bottom: 1px solid #AFE6FF;
                                                    .portrait {
                                                        width: 6%;
                                                        margin-top: 10px;
                                                        display: inline-block;
                                                        text-align: center;
                                                        img {
                                                            width: 40px;
                                                            height: 40px;
                                                            border-radius: 50px;
                                                            margin: auto;
                                                        }
                                                        p {
                                                            width: 100%;
                                                            color: #999999;
                                                            text-align: center;
                                                            white-space:nowrap;
                                                            overflow:hidden;
                                                            text-overflow:ellipsis;
                                                        }
                                                    }
                                                    .intR-content {
                                                        width: 92%;
                                                        padding-left: 20px;
                                                        justify-content: flex-end;
                                                        padding-top: 20px;
                                                        box-sizing: border-box;
                                                        .tR01 {
                                                            width: 100%;
                                                            height: 26px;
                                                            font-weight: bold;
                                                            display: flex;
                                                            .lT-A1 {
                                                                width: 650px;
                                                                justify-content: flex-start;
                                                                color: #1A86FF;
                                                                white-space:nowrap;
                                                                overflow:hidden;
                                                                text-overflow:ellipsis;
                                                            }
                                                            .lT-A2 {
                                                                width: 200px;
                                                                text-align: right;
                                                                justify-content: flex-end;
                                                                color: #999;
                                                            }
                                                        }
                                                        .tR02 {
                                                            width: 100%;
                                                            max-height: 200px;
                                                            overflow-y: auto;
                                                            .lT-content {
                                                                width: 100%;
                                                                justify-content: flex-start;
                                                                color: #333;
                                                                ::v-deep p {
                                                                    word-wrap: break-word;
                                                                    white-space: normal;
                                                                    word-break: break-all;
                                                                    img {
                                                                        border: 1px solid #eee;
                                                                        height: 160px;
                                                                        display: block;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }
                                                }
                                            }
                                            /* 聊天列表 */
                                            .lt-inf {
                                                padding: 0 10px;
                                                box-sizing: border-box;
                                                height: 560px;
                                                margin: auto;
                                                overflow-y: auto;
                                                .lt-list {

                                                }
                                                .ansBtnB {
                                                    height: 24px;
                                                    padding: 0px 10px;
                                                }
                                            }
                                            /* 聊天回复 */
                                        }
                                    }
                                    .focus_main6 {
                                        width: 98%;
                                        height: 730px;
                                        margin: 10px auto;
                                        border: solid 1px #ccc;
                                        position: relative;
                                        .tab {
                                            width: 100%;
                                            position: absolute;
                                            display: flex;
                                            // zuob
                                            .problem {
                                                width: 30%;
                                                overflow: hidden;
                                                padding: 0;
                                                margin: 0;
                                                float: left;
                                                .prolist {
                                                    box-sizing: border-box;
                                                    padding: 10px 0 0 0;
                                                    float: left;
                                                    width: 100%;
                                                    padding-bottom: 6px;
                                                    padding-left: 10px;
                                                    box-sizing: border-box;
                                                    list-style: none;
                                                    text-align: left;
                                                    .pl01 {
                                                        width: 96%;
                                                        line-height: 20px;
                                                        margin: auto;
                                                        display: flex;
                                                        .pA {
                                                            width: 70%;
                                                        }
                                                        .pB {
                                                            width: 30%;
                                                            text-align: right;
                                                        }
                                                    }
                                                    .pl02 {
                                                        width: 96%;
                                                        height: 36px;
                                                        line-height: 36px;
                                                        margin: auto;
                                                        display: flex;
                                                        .pA {
                                                            justify-content: flex-start;
                                                            color: #999;
                                                        }
                                                        .pB {
                                                            justify-content: center;
                                                            color: #999;
                                                        }
                                                        .pC {
                                                            justify-content: flex-end;
                                                            color: #1A86FF;
                                                            text-align: right;
                                                        }
                                                    }
                                                }
                                                .activef {
                                                    border-left: 3px solid #1A86FF;
                                                    background-color: #E8F7FE;
                                                    color:#1A86FF ;
                                                }
                                            }
                                            .prolistHd {
                                                width: 1300px;
                                                height: 729px;
                                                display: none;
                                                background-color: #E8F7FE;
                                                border-top: 0px;


                                            }
                                            .current {
                                                display: block;
                                                /* 聊天列表 */
                                                .lt-inf {
                                                    padding: 0 10px;
                                                    box-sizing: border-box;
                                                    height: 620px;
                                                    margin: auto;
                                                    overflow-y: scroll;
                                                }

                                            }

                                        }

                                    }
                                }
                                /*一课一练*/
                                .exercisesModular {
                                    width: 100%;
                                    margin: auto auto 0px auto;
                                    background-color: #fff;
                                    .top {
                                        width: 98%;
                                        height: 34px;
                                        line-height: 34px;
                                        margin: 10px auto;
                                        box-sizing: border-box;
                                        display: flex;
                                        .lf{
                                            width: 50%;
                                            justify-content: flex-start;
                                        }
                                        .rg{
                                            width: 50%;
                                            text-align: right;
                                            justify-content: flex-end;
                                        }
                                    }
                                    // 试题与答题卡
                                    .topicOr-card {
                                        display: flex;
                                        .kong {
                                            width: 400px;
                                            margin: auto;
                                            text-align: center;
                                            img {
                                                margin: auto;
                                                height: 200px;
                                            }
                                            p {
                                                height: 30px;
                                                line-height: 30px;
                                                color: #c6c6c6;
                                                span {
                                                    color: #238bff;
                                                    cursor:pointer
                                                }
                                            }
                                        }
                                        .card-cod {
                                            width: 100%;

                                            .tCart {
                                                width: 100%;
                                                display: flex;
                                                .st {
                                                    width: 50%;
                                                    height: 650px;
                                                    justify-content: flex-start;
                                                    overflow-y: auto;
                                                }
                                                .dt {
                                                    width: 50%;
                                                    height: 650px;
                                                    justify-content: flex-end;
                                                    overflow-y: auto;
                                                }
                                            }

                                        }


                                    }
                                }
                            }
                        }
                    }


                    .blackName2 {
                        width: 100%;
                        height: 34px;
                        line-height: 24px;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        border-bottom: 1px solid #f1f1f1;
                        display: flex;

                        .lf {
                            width: 50%;

                            img {
                                vertical-align: middle;
                                margin-top: -2px;
                            }
                        }

                        .rg {
                            width: 50%;
                            display: flex;
                            justify-content: flex-end;
                            text-align: right;

                            .k {
                                padding-left: 30px;
                                background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 5px;
                                color: #fff;
                                border: 1px solid #06A8C9;

                            }
                        }
                    }
                    // 试题与答题卡
                    .focus_main2 {
                        height: 800px;
                        border: solid 1px #ccc;
                        display: flex;
                        .kong {
                            width: 400px;
                            margin: auto;
                            text-align: center;
                            img {
                                margin: auto;
                                height: 200px;
                            }
                            p {
                                height: 30px;
                                line-height: 30px;
                                color: #c6c6c6;
                                span {
                                    color: #238bff;
                                    cursor:pointer
                                }
                            }
                        }
                        .st {
                            width: 50%;
                            height: 790px;
                            overflow-y: auto;
                        }
                        .dt {
                            width: 50%;
                            height: 790px;
                            overflow-y: auto;
                        }

                    }
                }

                /*操作按钮*/
                .Btn {
                    width: 14px;
                    height: 60px;
                    margin-top: -480px;
                    position: absolute;
                    margin-left: 100%;
                    opacity: 1;

                    .BtnA {
                        width: 14px;
                        height: 60px;
                        background: url("../../assets/images/arrow-right.png") no-repeat;
                        background-size: cover;
                        color: #007B94;
                        cursor: pointer;
                        padding-top: 20px;
                        padding-right: 6px;
                        position: absolute;
                        margin-left: -14px;
                        box-sizing: border-box;
                        border-radius: 5px 0px 0px 5px;

                        z-index: 99;
                    }

                    .BtnB {
                        width: 14px;
                        height: 60px;
                        background: url("../../assets/images/arrow-left.png") no-repeat;
                        background-size: cover;
                        color: #007B94;
                        cursor: pointer;
                        padding-top: 20px;
                        padding-right: 10px;
                        box-sizing: border-box;
                        border-radius: 0px;
                        z-index: 99;
                    }
                }
            }
            .area-rg {
                width: 25%;
                background-color: #FFF7CF;
                border-left: 5px solid #FFE568;
                //background: #E7F1F3;
                box-sizing: border-box;
                float: right;



                .bick-r-01 {
                    width: 100%;
                    height: 74px;
                    padding: 5px;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    cursor:pointer;
                    img {
                        width: 100%;
                        height: 74px;
                    }
                }

                .bick-r-02 {
                    width: 100%;
                    height: 1254px;
                    padding: 10px;
                    box-sizing: border-box;
                    .blackName {
                        width: 100%;
                        height: 30px;
                        line-height: 24px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        border-bottom: 1px solid #E5E5E5;

                        img {
                            width: 30px;
                            vertical-align: middle;
                            margin-top: -2px;
                        }
                    }
                    .note {
                        width: 100%;
                        padding-bottom: 20px;
                        box-sizing: border-box;
                        margin: auto;
                        .nodeBR {
                            height: 1110px;
                            overflow-y: auto;
                            .notecase {
                                width: 100%;
                                margin-bottom: 20px;

                                .cName {
                                    width: 100%;
                                    background: linear-gradient(90deg, #FF9100 0%, rgba(255, 145, 0, 0) 100%);
                                    height: 24px;
                                    line-height: 24px;
                                    display: flex;
                                    .Aam1 {
                                        width: 88%;
                                        height: 24px;
                                        box-sizing: border-box;
                                        padding-left: 20px;
                                        color: #fff;
                                    }
                                    .Aam2 {
                                        width: 30px;
                                        color: #2c3e50;
                                        i {
                                            width: 30px;
                                            margin-left: 20px;
                                            color: #ffcd78;
                                        }


                                    }
                                }
                                .cList {
                                    width: 100%;
                                    .list {
                                        width: 100%;
                                        display: flex;
                                        position: relative;
                                        .LA {
                                            width: 10px;
                                            height: 24px;
                                            box-sizing: border-box;

                                            padding-top: 18px;
                                            .yd {
                                                width: 3px;
                                                height: 3px;
                                                background-color: #238bff;
                                                border-radius: 50px;
                                            }
                                        }
                                        .LB {
                                            width: 100%;
                                            padding-top: 6px;
                                            border-bottom: 0px solid #E5E5E5;
                                            .Ob{
                                                line-height: 24px;
                                                height: 70px;
                                                overflow-y: auto;
                                            }
                                            ::v-deep.el-input__inner {
                                                width: 100%;
                                                background-color: #FBF8D9;
                                                height: auto;
                                                border-radius: 0px;
                                                border-top-width: 0px;
                                                border-left-width: 0px;
                                                border-right-width: 0px;
                                                border-bottom-width: 0px;
                                                /*outline: medium;*/
                                            }
                                            ::v-deep.el-textarea__inner {
                                                display: block;
                                                resize: vertical;
                                                padding: 0px 15px;
                                                line-height: 20px;

                                                box-sizing: border-box;
                                                width: 100%;
                                                color: #606266;
                                                background-color: #FBF8D9;
                                                background-image: none;
                                                border-top-width: 0px;
                                                border-left-width: 0px;
                                                border-right-width: 0px;
                                                border-bottom: 0px solid #E5E5E5;
                                                border-radius: 0px;
                                                transition: none;
                                            }
                                            #textarea {
                                                width: 280px;
                                                background-color: #FBF8D9;
                                                height: auto;
                                                line-height: 24px;
                                                border-radius: 0px;
                                                border-top-width: 0px;
                                                border-left-width: 0px;
                                                border-right-width: 0px;
                                                border-bottom-width: 1px;
                                                /*outline: medium;*/
                                            }

                                        }
                                    }
                                }
                            }
                        }

                    }

                    .noteBTn {
                        width: 100%;
                        height: 38px;
                        padding: 5px 0px 12px 10px;
                        box-sizing: border-box;
                        text-align: center;
                        margin: auto;
                        display: flex;
                        justify-content: center;
                        .bTn {
                            height: 36px;
                        }
                    }
                }

            }
        }




        .buyDialogA1 {
            ::v-deep.el-dialog__header {
                padding: var(--el-dialog-padding-primary);
                font-weight: 800;
                height: 46px;
                line-height: 46px;
                box-sizing: border-box;
                padding-left: 16px;
                border-bottom: 0px solid #f2f2f2;
                padding-bottom: 0px;
            }

            ::v-deep.el-dialog__body {
                padding: 0px;
                color: #606266;
                word-break: break-all;
            }
            .buyDialogT {
                width: 100%;
                padding-top: 20px;
                padding-bottom: 20px;
                text-align: center;
                i {
                    color: #ff9100;
                    margin: auto;
                }
            }
            .buyDialogA1-01 {
                width: 100%;
                padding: 20px 0px;

                p {
                    line-height: 30px;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    color: #999;
                }

                .logA {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;

                    span {
                        color: #FF5A00;
                    }
                }

            }



            .buyDialogA1-03 {
                width: 100px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                margin: 20px auto 40px auto;
                border-radius: 6px;
                background-color: #008DF0;
                color: #fff;
            }

            .buyDialogA1-03:hover {
                background-color: #0EBEC6;
            }
            .buyDialogA1-04 {
                width: 100%;
                height: 200px;
                text-align: center;
                padding-top: 20px;
                color: #000;
                .el-icon-success {
                    color: #6eb864;
                    vertical-align: middle;
                    margin-right: 10px;
                }
                .el-icon-warning {
                    color: #cf9236;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            .buyDialogA1-05 {
                width: 100%;
                height: 40px;
                padding-bottom: 30px;
                text-align: center;
                padding-top: 20px;
                color: #fff;
            }
        }
        .case-conent-detal {
            width: 98%;
            margin: 20px auto;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            opacity: 0.9;










            /* 聊天发布 */
            .lt-fb {
                padding: 0 10px;
                box-sizing: border-box;
                height: 100px;
                margin: auto;
                display: flex;
                .pA {
                    width: 90%;
                    justify-content: flex-start;
                }
                .pB {
                    width: 10%;
                    text-align: right;
                    justify-content: flex-end;
                }
            }



        }
    }
    .TcReply {
        background-color: #B2E7FF;
    }
    .AnswerPadBox {
        width: 96%;
        height: 700px;
        margin: auto;
        .sign-canvas{
            display: block;
            margin: 0 auto;
            border: 1px dashed #AFE6FF;
        }
        .view-image{
            display: block;
            margin: 20px auto;
        }
        .sign-btns{
            width: 800px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            #clear,
            #clear1,
            #save {
                margin: 0 auto;
                display: inline-block;
                padding: 5px 10px;
                width: 150px;
                height: 40px;
                line-height: 40px;
                border: 1px solid #eee;
                background: #e1e1e1;
                border-radius: 10px;
                text-align: center;
                margin: 20px auto;
                cursor: pointer;
            }
        }
    }
    .dialogDy {
        width: 96%;
        margin: auto;
        .dy1 {
            width: 100%;
            padding-bottom: 10px;
            display: flex;
            .dy-lf {
                width: 10%;
                justify-content: flex-start;
            }
            .dy-rg {
                width: 90%;
                justify-content: flex-end;
            }
        }
        .dialog-footer {
            .bTn {
                border: 1px solid #FF9100; background-color: #FF9100; color: #fff
            }
        }
    }
    .AnswerForm {
        width: 98%;
        margin: auto;
        .list {
            width: 100%;
            max-height:300px;
            overflow-y:auto;
            ::v-deep p {
                word-wrap: break-word;
                white-space: normal;
                word-break: break-all;
                img {
                    border: 1px solid #eee;
                    height: 160px;
                    display: block;
                }
            }
            ::v-deep.el-form-item {
                margin-bottom: 0px;
            }
        }
        .listBY {
            width: 100%;
            max-height:320px;
            overflow-y:auto;

        }
    }
    // 作业提交提示
    .TipM {
        width: 96%;
        margin: auto;
        .Msg {
            width: 100%;
            text-align: center;
            h2 {
                color: #FF4D00;
            }
            p {
            }
            .R {
                color: #999;
                padding-top: 20px;
            }
        }
        .Mtn {
            width: 100%;
            margin-top: 20px;
            text-align: center;

        }
    }
    .pag-box {
        width: 100%;
        padding-top: 10px;
        box-sizing: content-box;
        justify-content: center;
        display: flex;

        .total-box {
            float: left;
            line-height: 32px;
            margin-right: 10px;
            justify-content: flex-end;
            display: flex;

            .el-pagination {
                white-space: nowrap;
                padding: 2px 5px 2px 0px;
                color: #303133;
                font-weight: 700;

                .el-pager li {
                    background: #ccc;
                    -webkit-box-sizing: border-box;
                }

                .el-pager li .active {
                    background-color: #FF6820;
                    color: #FFF;
                }
            }


        }

    }
    .UediWrapA{
        width: 99%;
        border: 1px solid #d4d4d4;
        background-color:#FFF;
        position: relative;
        overflow: visible;
        border-radius: 4px;
        .EtorToolbarboxA {
            width: 100%;
            height: 34px;
            line-height: 34px;
            position: relative;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom: 1px solid #d4d4d4;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
            display: flex;
            .Etor-01 {
                width: 20%;
                height: 30px;
                float: left;
                justify-content: flex-start;
                background: url("../../assets/images/skPad.png") no-repeat 15px 2px;
                background-size:26px 26px;
            }
            .Etor-02 {
                width: 80%;
                height: 30px;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
                box-sizing: border-box;
                padding-right: 10px;
                float: right;
                color: #b4bccc;
                /*background: url("../../assets/images/skPad.png") no-repeat 15px 7px;*/
                background-size:16px 16px;
            }

        }
        .EtorToolbarboxB {
            height: 300px;
            padding: 10px;
            overflow-y: auto;
            box-sizing: border-box;
            .studentAnswer {
                width: 98%;
                margin: auto;
                //滚动条的宽度
                ::v-deep ::-webkit-scrollbar {
                    width: 8px;
                    height: 10px;
                    cursor: pointer
                }

                //滚动条的滑块
                ::v-deep ::-webkit-scrollbar-thumb {
                    background-color: #3595ff;
                    border-radius: 12px;
                    cursor: pointer
                }
                ::v-deep p {
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    img {
                        border: 1px solid #c6c6c6;
                        height: 200px;
                        display: block;
                    }
                }
            }




        }
    }
    .UediWrapB{
        width: 99%;
        border: 1px solid #d4d4d4;
        background-color:#FFF7CF;
        position: relative;
        overflow: visible;
        border-radius: 4px;
        .EtorToolbarboxA {
            width: 100%;
            height: 34px;
            line-height: 34px;
            position: relative;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom: 1px solid #d4d4d4;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
            display: flex;
            .Etor-01 {
                width: 20%;
                height: 30px;
                float: left;
                justify-content: flex-start;
                background: url("../../assets/images/skPad.png") no-repeat 15px 2px;
                background-size:26px 26px;
            }
            .Etor-02 {
                width: 80%;
                height: 30px;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
                box-sizing: border-box;
                padding-right: 10px;
                float: right;
                color: #b4bccc;
                /*background: url("../../assets/images/skPad.png") no-repeat 15px 7px;*/
                background-size:16px 16px;
            }

        }
        .EtorToolbarboxB {
            height: 300px;
            padding: 10px;
            overflow-y: auto;
            box-sizing: border-box;
            .studentAnswer {
                width: 98%;
                margin: auto;
                //滚动条的宽度
                ::v-deep ::-webkit-scrollbar {
                    width: 8px;
                    height: 10px;
                    cursor: pointer
                }

                //滚动条的滑块
                ::v-deep ::-webkit-scrollbar-thumb {
                    background-color: #3595ff;
                    border-radius: 12px;
                    cursor: pointer
                }
                ::v-deep p {
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    img {
                        border: 1px solid #c6c6c6;
                        height: 200px;
                        display: block;
                    }
                }
            }




        }
    }

    .ueditorWrap{
        width: 100%;
        border: 1px solid #d4d4d4;
        background-color: #fff;
        position: relative;
        overflow: visible;
        border-radius: 4px;
        .EtorToolbarboxA {
            width: 100%;
            height: 30px;
            position: relative;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom: 1px solid #d4d4d4;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
            .Etor-01 {
                width: 60px;
                height: 30px;
                float: left;
                justify-content: flex-start;
                background: url("../../assets/images/skPad.png") no-repeat 15px 2px;
                background-size:26px 26px;
            }
            .Etor-02 {
                width: 40px;
                height: 30px;
                justify-content: flex-end;
                float: right;
                /*background: url("../../assets/images/skPad.png") no-repeat 15px 7px;*/
                background-size:16px 16px;
            }

        }
        .EtorToolbarboxB {
            height: 300px;
            padding: 10px;
            overflow-y: auto;
            box-sizing: border-box;
            .studentAnswer {
                width: 98%;
                margin: auto;
                //滚动条的宽度
                ::v-deep ::-webkit-scrollbar {
                    width: 8px;
                    height: 10px;
                    cursor: pointer
                }

                //滚动条的滑块
                ::v-deep ::-webkit-scrollbar-thumb {
                    background-color: #3595ff;
                    border-radius: 12px;
                    cursor: pointer
                }
                ::v-deep p {
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    img {
                        border: 1px solid #c6c6c6;
                        height: 200px;
                        display: block;
                    }
                }
            }




        }
    }
    .ueditorWrap2{
        width: 420px;
        border: 1px solid #FFDAA8;
        background-color: #FBF8D9;
        position: relative;
        overflow: visible;
        border-radius: 4px;
        .EtorToolbarboxA {
            width: 100%;
            height: 30px;
            position: relative;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom: 1px solid #FFDAA8;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
            .Etor-01 {
                width: 60px;
                height: 30px;
                float: left;
                justify-content: flex-start;
                background: url("../../assets/images/Case-10.png") no-repeat 15px 7px;
                background-size:16px 16px;
            }
            .Etor-02 {
                width: 40px;
                height: 30px;
                justify-content: flex-end;
                float: right;
                background: url("../../assets/images/Case-11.png") no-repeat 15px 7px;
                background-size:16px 16px;
            }

        }
        .EtorToolbarboxB {
            height: 300px;



        }
    }






















    .addclass {
        color: red;
    }
    .CaseMenu {
        line-height: 40px;
        cursor:pointer;
        border-left: 1px solid #84c8ed;
        border-right: 1px solid #84c8ed;
        text-align: center;
        color: #fff;
    }
    .active {
        color: #fff;
        background: #0055C5 ;
        opacity: 1;
    }
    .edu-container {
        margin: auto;
    }







    .intlist {
        width: 98%;
        margin: auto;
        padding: 10px 10px;
        box-sizing: border-box;
        display: flex;
        border-bottom: 1px solid #AFE6FF;
        .portrait {
            width: 6%;
            justify-content: flex-start;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50px;
            }
        }
        .intR {
            width: 94%;
            justify-content: flex-end;
            .tR01 {
                width: 100%;
                height: 40px;
                line-height: 40px;
                display: flex;
                .lf {
                    width: 50%;
                    color: #999999;
                    text-align: left;
                    justify-content: flex-start;
                }
                .rg {
                    width: 50%;
                    color: #999999;
                    text-align: right;
                    justify-content: flex-end;
                }
            }
            .tR02 {
                width: 100%;
                word-wrap: break-word;
                white-space: normal;
                word-break: break-all;
                ::v-deep p {
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    img {
                        border: 1px solid #eee;
                        height: 160px;
                        display: block;
                    }
                }
            }
            .tR03 {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                div {
                    vertical-align: middle;
                    cursor: pointer;
                    img {
                        margin-right: 6px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .Lintlist {
        width: 94%;
        margin: auto 1% auto auto;
        padding: 10px 0;
        display: flex;
        float: right;
        border-bottom: 1px solid #AFE6FF;
        .portrait {
            width: 6%;
            justify-content: flex-start;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50px;
            }
        }
        .intR {
            width: 94%;
            justify-content: flex-end;
            .tR01 {
                width: 100%;
                height: 40px;
                line-height: 40px;
                display: flex;
                .lf {
                    width: 50%;
                    color: #999999;
                    text-align: left;
                    justify-content: flex-start;
                }
                .rg {
                    width: 50%;
                    color: #999999;
                    text-align: right;
                    justify-content: flex-end;
                }
            }
            .tR02 {
                width: 100%;
                ::v-deep p {
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    img {
                        border: 1px solid #eee;
                        height: 160px;
                        display: block;
                    }
                }
            }
            .tR03 {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                div {
                    vertical-align: middle;
                    img {
                        margin-right: 6px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .AnswerSheet {
        width: 96%;
        overflow-y: auto;
        margin: auto;
        overflow-x: hidden;

        .Acontent {
            width: 100%;
            padding: 0px 20px 0px 0px;
            display: inline-block;
            .Aname {
                line-height: 40px;
                font-weight: bold;
            }
            .Acn {
                padding:  0 6px 10px 0px;
            }
            .Alist {
                width: 100%;
                line-height: 30px;
                position: relative;
                display: flex;
                .A1 {
                    width: 20px;
                    float: left;
                }
                .A2 {
                    width: 100%;
                    float: left;
                    padding-left: 20px;
                    box-sizing: border-box;
                    /*选项*/
                    .Aoption {
                        width: 100%;
                        display: flex;
                        .A-tem {
                            width: 60px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border: 1px solid #707070;
                            margin: 0 10px;
                        }
                    }
                    /*非选项*/
                    .Foption {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
            // 批改结果
            .Alist-Results {
                width: 100%;
                line-height: 30px;
                position: relative;
                display: flex;
                .A1 {
                    width: 20px;
                    float: left;
                }
                .A2 {
                    width: 100%;
                    float: left;
                    padding-left: 20px;
                    box-sizing: border-box;
                    /*选项*/
                    .Aoption {
                        width: 100%;
                        display: flex;
                        .A-tem {
                            width: 60px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border: 1px solid #707070;
                            margin: 0 10px;
                        }
                    }
                    /*非选项*/
                    .Foption {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
            .Alist-D {
                width: 100%;
                margin: 10px 0px;
                padding: 5px 0px;
                box-sizing: border-box;
                position: relative;
                .A1 {
                    width: 30px;
                    height: 24px;
                    line-height: 24px;
                    text-align: right;
                    box-sizing: border-box;
                    padding-right: 10px;
                    float: left;
                    position: absolute;
                }
                .A2 {
                    width: 100%;
                    padding-left: 40px;
                    box-sizing: border-box;
                    display: flex;
                    float: left;
                    /*选项*/
                    .Aoption {
                        width: 620px;
                        padding-right: 20px;
                        display: flex;
                        .A-tem {
                            width: 40px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            border: 1px solid #707070;
                            margin: 0 10px;
                        }

                    }
                    /*是否正确*/
                    .correct-Box {
                        width: 200px;
                        img {
                            margin-top: 5px;
                            margin-right: 20px;
                        }
                    }
                    /*非选项*/
                    .Foption {
                        width: 100%;
                        .A-tem {
                            width: 100%;
                            height: 30px;
                            border-bottom: 1px solid #303133;
                            margin: 0 10px;
                        }
                    }
                }
                .A3 {
                    width: 500px;
                    float: left;
                    .D1 {
                        width: 100%;
                        box-sizing: border-box;
                        padding-left: 40px;
                        display: flex;
                        .D1-A1 {
                            width: 65px;
                            background-color: #FA6E86;
                            height: 24px;
                            line-height: 24px;
                        }
                        .D1-A2 {
                            line-height: 24px;
                            width: 390px;
                        }
                    }
                }

            }
            .Blist-E {
                width: 100%;
                margin: 10px 0px;
                padding: 5px 0px;
                display: inline-block;
                box-sizing: border-box;
                position: relative;
                .A1 {
                    width: 30px;
                    height: 24px;
                    line-height: 24px;
                    text-align: right;
                    box-sizing: border-box;
                    padding-right: 10px;
                    float: left;
                    position: absolute;
                }
                .A2 {
                    width: 100%;
                    padding-left: 40px;
                    box-sizing: border-box;
                    display: flex;
                    float: left;
                    .Da-1 {
                        width: 620px;
                        margin-top: 0px;
                        margin-right: 20px;
                        box-sizing: border-box;
                        background-color: #FBF8D9;
                        line-height: 24px;
                        border: 1px solid #e5e9f2;
                    }
                    .Da-2 {
                        width: 620px;
                        margin-top: 0px;
                        padding-right: 20px;
                        box-sizing: border-box;
                        line-height: 24px;
                        ::v-deep.AoptionB {
                            width: 100%;
                            display: flex;
                            p {
                                word-wrap: break-word;
                                white-space: normal;
                                word-break: break-all;
                                img {
                                    border: 1px solid #c6c6c6;
                                    height: 200px;
                                    display: block;
                                }
                            }
                        }
                    }
                    /*选项*/

                    /*是否正确*/
                    .correct-Box {
                        width: 200px;
                        img {
                            margin-top: 5px;
                            margin-right: 20px;
                        }
                    }
                    /*非选项*/
                    .Foption {
                        width: 100%;
                        .A-tem {
                            width: 100%;
                            height: 30px;
                            border-bottom: 1px solid #303133;
                            margin: 0 10px;
                        }
                    }

                }
                .A3 {
                    width: 750px;
                    float: left;
                    .D1 {
                        width: 100%;
                        box-sizing: border-box;
                        padding-left: 50px;
                        display: flex;
                        .D1-A1 {
                            height: 30px;
                            line-height: 30px;
                        }
                        .D1-A2 {
                            line-height: 30px;
                            width: 390px;
                        }
                    }
                }

            }
        }

    }
    .active{
        border:3px solid #FF9100;
    }

    //.pic-item{
    //  width:230px;
    //  height: 98px;
    //  background-color: red;
    //  margin-bottom: 10px;
    //}
    .pic-item{
        width:230px;
        height: 104px;
        margin-bottom: 6px;
        position:relative;
        z-index:1;

    }
    .pic-item img{
        height: 104px;
        position:absolute;
        margin: 0px auto;
        left:0;
        right:0;
        width:100%;
        z-index: -1;
        *zoom:1;
    }
    .pic-item:before {
        content: "";
        display: inline-block;
        padding-bottom: 100%;
        width: 0.1px; /*必须要有数值，否则无法把高度撑起来*/
        vertical-align: middle;
    }

    .el-carousel__item h3 {
        color: #475669;
        opacity: 1;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #999;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #999;
    }
    .Loading-backg {
        height: 100%;
        background-color: #fff;
        .kong-loshd {
            width: 400px;
            margin: auto;
            padding-top: 8%;
            box-sizing: border-box;
            text-align: center;
            img {
                margin: auto;
                height: 200px;
            }
            p {
                height: 30px;
                line-height: 30px;
                color: #c6c6c6;
                span {
                    color: #238bff;
                    cursor:pointer
                }
            }
        }
    }



</style>
